import React from 'react';
import Footer from '../Page/Footer'

const Responsivegaming = () => {
  return (
    <div>
    <div className="terms-container">
    <h1 className="terms-heading">Responsible Gaming</h1>
      
      <p className="terms-paragraph">
        At Leobook, we are committed to ensuring that your betting experience remains enjoyable and responsible. We understand that while many people enjoy betting as a form of entertainment, it is important to approach it with caution. This page provides information and resources to help you gamble responsibly and seek support if needed.
      </p>
      
      <section className="terms-section">
        <h2>1. Understanding Responsible Gaming</h2>
        <p>
          Responsible gaming means being aware of the risks associated with gambling and managing your betting activities to ensure they do not negatively impact your life. It involves setting limits on your time and spending, understanding the odds, and recognizing when gambling becomes a problem.
        </p>
      </section>
      
      <section className="terms-section">
        <h2>2. Setting Limits</h2>
        <p>
          We encourage you to set personal limits on your betting activities. Leobook offers tools that allow you to set deposit limits, session time limits, and loss limits. These tools help you manage your betting behavior and prevent excessive gambling.
        </p>
      </section>
      
      <section className="terms-section">
        <h2>3. Self-Exclusion</h2>
        <p>
          If you feel that you need a break from betting, Leobook provides self-exclusion options. You can temporarily suspend your account or opt for a longer-term self-exclusion period. During this time, you will not be able to access your account or place bets.
        </p>
      </section>
      
      <section className="terms-section">
        <h2>4. Recognizing Problem Gambling</h2>
        <p>
          It’s important to recognize the signs of problem gambling. These may include betting more than you can afford, experiencing financial difficulties due to gambling, or feeling anxious or stressed about your gambling activities. If you notice these signs in yourself or someone you know, it may be time to seek help.
        </p>
      </section>
      
      <section className="terms-section">
        <h2>5. Seeking Help</h2>
        <p>
          If you or someone you know needs help with gambling-related issues, there are numerous resources available. We encourage you to reach out to professional support organizations for assistance. In addition, you can contact our support team for guidance on responsible gaming practices.
        </p>
      </section>
      
      <section className="terms-section">
        <h2>6. Contact Us</h2>
        <p>
          For further assistance or if you have any concerns about your gambling habits, please do not hesitate to contact us. You can reach our support team at <a href="#">support@leobook.com</a> or through our live chat feature available on the website.
        </p>
      </section>
    </div>
    <Footer/>

    </div>
  );
};

export default Responsivegaming;
