import React ,{useState,useEffect}from 'react'
import axios from 'axios';

export default function Brodcast() {
  const [broadcast, setBroadcast] = useState([]);
  const [error, setError] = useState(null);

  const fetchSportData = async () => {
    try {
      const response = await fetch(
        `https://leobook.in/api/broadcast.php`,
        {
          method: 'GET',  // Explicitly specify GET method
          headers: {
          },
        }
      );

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      setBroadcast(data.data);
      console.warn(data);
    } catch (error) {
      setError('Error fetching sport data: ' + error.message);
      console.error('Error fetching sport data:', error);
    }
  };

  useEffect(() => {
    fetchSportData();
  }, []);  


  return (
    <div className="Broadcast">
       {error && <p>{error}</p>}  
      {broadcast.length > 0 ? (
        broadcast.map((item, index ) => (

          <div  className="Notification_design d-flex gap-2" key={index}>
            <div className="image_user">
            <img src={item.media}/>
          </div> 
          <div className="notification_heading">
                    <div className='heading'>{item.title} </div>
                    <div className='description'>{item.message}</div>
                    <div className='datetime d-flex'>
                        <div className='date'>{item.date}</div>
                    </div>
                </div>
          </div> 
        ))
      ) : (
        <p>No data available</p>  
      )}
         
    </div>
  )
}
