import { Container } from "react-bootstrap";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  Link,
} from "react-router-dom";
import Login from "../src/Login/Login";
import Index from "../src/Page/Index";
import Home from "../src/Page/Home";
import Header from "../src/Layout/Header";
import Sidebar from "./Layout/Sidebarleft";
import WalletAmount from "./Layout/WalletAmount";
import Topnavbar from "../src/Page/Topnavbar";
import Bettingpage from "../src/Page/Bettingpage";
import Livecasino from "../src/Page/Livecasino";
import Table from "../src/Login/Table";
import Setbuttonvalue from "../src/Page/Setbuttonvalue/Setbuttonvalue";
import Accountstatement from "../src/Page/Accountstatement/Accountstatement";
import Bethistory from "../src/Page/Bethistory/Bethistory";
import Unsettledbet from "../src/Page/Unsettledbet/Unsettledbet";
import Termsandcondition from "../src/Page/Termsandcondition";
import Gamerule from "../src/Page/Gamerule";
import Aboutus from "../src/Page/Aboutus";
import Responsivegaming from "../src/Page/Responsivegaming";
import Faq from "../src/Page/Faq";
import Cricket from "../src/Page/Cricket/Cricket";
import Brodcast from "../src/Page/Brodcast/Brodcast";
import Logo from "../src/assets/images/logosec.png";

const MainLayout = () => {
  const location = useLocation();
  const hideSidebarPaths = [
    "/Accountstatement",
    "/Bethistory",
    "/Setbuttonvalue",
    "/Unsettledbet",
    "/Termsandcondition",
    "/Gamerule",
    "/Aboutus",
    "/Faq",
    "/Responsivegaming",
  ];
  const shouldHideSidebar = hideSidebarPaths.includes(location.pathname);
  return (
    <div id="main-wrapper">
      <Header />
      {!shouldHideSidebar && <Sidebar />}
      {!shouldHideSidebar ? (
        <div className="content-body">
          <Container fluid>
            <Topnavbar key={location.search} />
            <Routes>
              <Route path="/index" element={<Index key={location.search} />} />
              <Route path="/Home" element={<Home />} />
              <Route path="/Bettingpage" element={<Bettingpage />} />
              <Route path="/livecasino" element={<Livecasino />} />
              <Route path="/Unsettledbet" element={<Unsettledbet />} />
              <Route path="/Setbuttonvalue" element={<Setbuttonvalue />} />
              <Route path="/Accountstatement" element={<Accountstatement />} />
              <Route path="/Bethistory" element={<Bethistory />} />
              <Route path="/Cricket" element={<Cricket />} />
              <Route path="/Table" element={<Table />} />
              <Route path="/Brodcast" element={<Brodcast />} />

              <Route
                path="/WalletAmount"
                element={<WalletAmount key={location.search} />}
              />
            </Routes>
          </Container>
        </div>
      ) : (
        <Container fluid>
          <div className="margin-top-40">
            <div className="d-flex otherpagegap justify-content-between">
              <div className="logo_design">
                <Link to="/Home">
                  <img src={Logo} alt="logo" />
                </Link>
              </div>
              <Topnavbar key={location.search} />
            </div>
            <Routes>
              <Route path="/Accountstatement" element={<Accountstatement />} />
              <Route path="/Bethistory" element={<Bethistory />} />
              <Route path="/Setbuttonvalue" element={<Setbuttonvalue />} />
              <Route
                path="/Termsandcondition"
                element={<Termsandcondition />}
              />

              <Route path="/Aboutus" element={<Aboutus />} />
              <Route path="/Gamerule" element={<Gamerule />} />
              <Route path="/Faq" element={<Faq />} />
              <Route path="/Responsivegaming" element={<Responsivegaming />} />
              <Route path="/Unsettledbet" element={<Unsettledbet />} />
            </Routes>
          </div>
        </Container>
      )}
    </div>
  );
};

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/*" element={<MainLayout />} />
      </Routes>
    </Router>
  );
}

export default App;
