import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import { useNavigate } from "react-router-dom";

function Unsettledbet() {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [betUnsettledData, setBetUnsettledData] = useState([]);
  const username = localStorage.getItem('username');
  const navigate = useNavigate();
  const fetchDataFromAPI = async () => {
    try {
      const token = localStorage.getItem("token");
      const apiUrl = `https://leobook.in/Admin/api/v1/unselleted-bet`;

      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch data from the API");
      }

      const data = await response.json();
      console.log(data.data);
      setBetUnsettledData(data.data);
      setIsLoading(false);
    } catch (error) {
      setError(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchDataFromAPI();
  }, []);
  const handleLinkClick = () => {
    navigate('/index');
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }
  return (
    <>
      {/* <div class="card-header tab_first d-flex" style={{ height: '55px' }}></div> */}
      <section className="padding_15">
        <main id="main" class="main bethistory">
          <div class="accountstatement d-flex justify-content-between">
            <h5 className='text-white'>Unsetteled Bet </h5>
            <button className="text-light btn bg-success text-info" onClick={handleLinkClick}>Back</button>
          </div>
          <div className="account_statement mt-2">
              <div className="d-flex align-items-end flex-wrap-mobile">
                  <div className="d-flex justify-content-between align-items-end width_100">
                  <Form.Select className="selectbet" aria-label="Default select example">
                      <option>Sport Type</option>
                      <option value="1">Cricket</option>
                      <option value="2">Tennis</option>
                      <option value="3">FootBall</option>

                    </Form.Select>
                    <Form.Select className="selectbet mx-1" aria-label="Default select example">
                      <option>Bet Status</option>
                      <option value="1">Matched</option>
                      <option value="2">Unmatched</option>

                    </Form.Select>
                  </div>
                  <form id="search" role="form" className="mb-0 d-flex align-items-end">
                    <div class="d-flex gap-2 align-items-end flex-wrap-mobile">
                      <div class="form-group mb-0">
                        <label>From Date</label>
                        <input
                          type="date"
                          class="form-custum"
                          value="2015-06-17"
                          id="dateFrom"
                        />
                      </div>

                      <div class="form-group mb-0">
                        <label>To Date</label>
                        <input
                          type="date"
                          class="form-custum"
                          value="2015-06-19"
                          id="dateTo"
                        />
                      </div>

                      <div className="d-flex align-items-end mt-3">
                        <button
                          class="btn_custom bg-color-section btn text-light"
                          type="button"
                          id="getJsonSrc"
                        >
                          {/* <i class="fa-solid fa-magnifying-glass pr-5"></i> */}
                          Go
                        </button>
                        <button
                          class="btn_custom bg-color-section  btn text-light mx-1"
                          type="button"
                          id="getJsonSrc"
                        >
                          {/* <i class="fa-solid fa-magnifying-glass pr-5"></i> */}
                          Reset
                        </button>
                      </div>
                    </div>
                  </form>
              </div>
          </div>
          <div class="account_statement">
            <div class="">

              <div class="mt-5">
                <div class="table-responsive">
                  <table class="table profit-loss-table accountstmt">
                    <thead>
                      <tr>
                        <th className="col-xs-1" data-field="date">
                          Event Type
                        </th>
                        <th
                          className="col-xs-1"
                          data-field="operator"
                        >
                          Event Name
                        </th>
                        {/* <th className="col-xs-2 wid280" data-field="type">
                                          UserName
                                        </th> */}
                        <th
                          className="col-xs-2"
                          data-field="subType"
                        >
                          Runner Name
                        </th>
                        <th
                          className="col-xs-5"
                          data-field="message"
                        >
                          Bet Type
                        </th>
                        <th
                          className="col-xs-5"
                          data-field="message"
                        >
                          User Rate
                        </th>
                        <th
                          className="col-xs-5"
                          data-field="message"
                        >
                          Amount
                        </th>
                        <th
                          className="col-xs-5"
                          data-field="message"
                        >
                          Place Date
                        </th>
                        <th className="col-xs-5" data-field="message">
                          Match Date

                        </th>
                      </tr>
                    </thead>
                    {/* <tbody>
                        {betUnsettledData.map((item) => (
                          <tr key={item.id}>
                            <td>NULL</td>
                            <td>{item.event_name}NULL</td>
                            <td>NULL</td>
                            <td>NULL</td>
                            <td>{item.bet_on}</td>
                            <td>{item.stake}</td>
                            <td>{item.created_at}</td>
                           
                          </tr>
                        ))}
                      </tbody> */}


                    <tbody>
                      {betUnsettledData.map((item) => (
                        <tr key={item.id}>
                          <td>{item.event_id}</td>
                          <td>{item.event_name ? item.event_name.name : 'N/A'}</td>
                          {/* <td>{username}</td> */}
                          <td>{item.team}</td>
                          <td>{item.bet_type}</td>
                          <td >{item.total}</td>
                          <td >{item.stake}</td>
                          <td >{item.created_at}</td>
                          <td>{item.created_at}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              {/* <div class="tab__content-itemthird">
                  <div class="table-responsive">
                    <table class="table profit-loss-table">
                      <thead>
                        <tr>
                          <th><span>Placed</span></th>
                          <th>Description</th>
                          <th>Type</th>
                          <th class="text-right">Odds</th>
                          <th class="text-right">Stake</th>
                          <th class="text-right"><span>Liability</span> </th>

                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td colspan="7" class="text-center">There are no record to display
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div> */}




            </div>
          </div>

        </main>
      </section>



    </>

  )
}

export default Unsettledbet;