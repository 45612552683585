import React from 'react';
import Footer from '../Page/Footer'

const GamingRules = () => {
  return (
    <div>
 <div className="terms-container">
 <h1 className="terms-heading">
        Gaming Rules for Leobook - Cricket Betting Guidelines
      </h1>
      <p className="terms-paragraph">
        Welcome to Leobook's Cricket Betting platform. These Gaming Rules govern your use of our cricket betting services. By participating in our betting activities, you agree to abide by these rules. If you disagree with any part of these rules, please refrain from placing bets. We may update these rules from time to time, and your continued use of our services constitutes acceptance of any changes.
      </p>
      <section className="terms-section">
        <h2>1. General Betting Rules</h2>
        <p>
          All cricket bets placed through Leobook must adhere to our general betting rules. These rules include the valid types of bets, minimum and maximum bet limits, and any specific terms related to cricket matches. Ensure you are familiar with these rules before placing a bet.
        </p>
      </section>
      <section className="terms-section">
        <h2>2. Match Outcomes</h2>
        <p>
          Bets on cricket matches are based on official match outcomes as determined by the governing cricket body. Leobook does not accept responsibility for errors or disputes related to match results. In case of a match being abandoned or postponed, bets will be settled according to our rules on match abandonment.
        </p>
      </section>
      <section className="terms-section">
        <h2>3. Live Betting</h2>
        <p>
          Live betting allows you to place bets during a cricket match. All live bets are subject to the same rules as pre-match bets. In the event of any interruption or delay during live betting, Leobook reserves the right to void or suspend bets based on the current match status.
        </p>
      </section>
      <section className="terms-section">
        <h2>4. Special Bets</h2>
        <p>
          Special bets may include individual player performances, specific events within a match, or other unique betting options. Each special bet will have its own set of rules and conditions, which will be clearly stated at the time of placing the bet.
        </p>
      </section>
      <section className="terms-section">
        <h2>5. Bet Cancellation and Modifications</h2>
        <p>
          Once placed, bets cannot be canceled or modified. Please review all bet details before confirming your wager. In exceptional cases, Leobook may consider bet cancellations or modifications at its discretion.
        </p>
      </section>
      <section className="terms-section">
        <h2>6. Result Disputes</h2>
        <p>
          Disputes regarding the outcomes of bets will be resolved based on official match results and data provided by reliable sources. Leobook’s decision on disputes is final and binding.
        </p>
      </section>
      <section className="terms-section">
        <h2>7. Odds and Payouts</h2>
        <p>
          Odds and potential payouts are displayed on the Leobook platform. These are subject to change before the bet is placed. Once a bet is confirmed, the odds at the time of placement will apply to the payout.
        </p>
      </section>
      <section className="terms-section">
        <h2>8. Responsible Betting</h2>
        <p>
          Leobook promotes responsible betting. We encourage you to bet responsibly and seek assistance if you find yourself struggling with gambling issues. Tools and resources are available to help you manage your betting activities.
        </p>
      </section>
      <section className="terms-section">
        <h2>9. Betting Limits</h2>
        <p>
          Betting limits may be applied to individual bets or accounts. These limits are in place to ensure fair play and manage risk. You can view and manage your betting limits through your account settings.
        </p>
      </section>
      <section className="terms-section">
        <h2>10. Promotion Terms</h2>
        <p>
          Any promotions or bonuses offered for cricket betting are subject to additional terms and conditions. These terms will be provided at the time of the promotion, and you are responsible for reviewing and understanding them.
        </p>
      </section>
      <section className="terms-section">
        <h2>11. System Errors</h2>
        <p>
          In the event of a system error or technical malfunction affecting bets, Leobook reserves the right to void or adjust affected bets. We will make reasonable efforts to address and rectify such errors.
        </p>
      </section>
      <section className="terms-section">
        <h2>12. Contact Us</h2>
        <p>
          For any questions or issues regarding these gaming rules or your cricket betting experience, please contact us at support@leobook.com. We are here to assist you and address any concerns.
        </p>
      </section>
    </div>
    <Footer/>
    </div>
  );
};

export default GamingRules;
