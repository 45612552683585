import React, { useState, useEffect } from "react";
import Form from 'react-bootstrap/Form';
import { useNavigate } from "react-router-dom";
function Accountstatement() {
  const currentDate = new Date();
  const nextDay = new Date(currentDate);
  nextDay.setDate(currentDate.getDate() + 1);
  const nextdays = nextDay.toISOString().split('T')[0];
  console.warn(nextdays)
  const [fromDate, setFromDate] = useState('2023-07-07');
  const [toDate, setToDate] = useState(nextdays);
  const [filteredData, setFilteredData] = useState({ Profilt: [], Loss: [] });

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const fetchDataFromAPI = async () => {
    try {

      const token = localStorage.getItem("token");
      const apiUrl =  `${process.env.REACT_APP_API_URL}account-statement`;
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      if (!response.ok) {
        throw new Error("Failed to fetch data from the API");
      }
      const responseData = await response.json();
      if (responseData.statusCode === 200) {
        const formattedData = responseData.data.Stetment.map(item => ({
          ...item,
          created_at: new Date(item.created_at.replace(" ", " ")).toISOString()
        }));
        
        
        let filteredData;
        if (fromDate && toDate) {
          const fromDateObj = new Date(fromDate);
          const toDateObj = new Date(toDate);
          filteredData = formattedData.filter(item => {
            const itemDate = new Date(item.created_at);
            return itemDate >= fromDateObj && itemDate <= toDateObj;
          });
        } else {
          
          filteredData = formattedData;
        }
        
        console.warn(filteredData)
        setFilteredData(filteredData);
        setIsLoading(false);
      } else {
        throw new Error(responseData.message || "Failed to fetch data from the API");
      }
    } catch (error) {
      setError(error);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchDataFromAPI();
  }, [fromDate, toDate]);

  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <div>Error: {error.message}</div>;
  }
  function formatDate(dateString) {
    const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' };
    const formattedDate = new Date(dateString).toLocaleString(undefined, options);
    return formattedDate.replace(/, /g, ' ');
  }
  const handleLinkClick = () => {
    navigate('/Home');
  };

  return (
    <div>
      <>
        {/* <div className="card-header tab_first d-flex" style={{ height: 55 }}></div> */}
        <section className="bethistory">
          <main id="main" className="main">
            <div className="container-fluid">
              <div className="accountstatement d-flex justify-content-between align-items-center">
                <h5 className="text-white">Account Statement</h5>
                <button className="text-light btn bg-success text-info" onClick={handleLinkClick}>Back</button>
              </div>
              <div className="account_statement">
                  <form id="search" role="form" style={{}}>
                    <div className="row d-flex align-items-end">
                      <div class="col-md-2 col-12 paddingsm-0">
                        <Form.Select className="form-select" aria-label="Default select example">
                          <option>Alll</option>
                          <option value="1">Profit/Loss</option>
                          <option value="2">Settlement</option>

                        </Form.Select>
                      </div>
                      <div className="col-md-2 col-6 ">
                        <label>From Date</label>
                        <input type="date" className="form-custum" value={fromDate} onChange={(e) => setFromDate(e.target.value)} />
                      </div>
                      <div className="col-md-2 col-6">
                        <label>To Date</label>
                        <input type="date" className="form-custum" value={toDate} onChange={(e) => setToDate(e.target.value)} />
                      </div>
                      <div className="col-md-2 d-flex align-items-flex-end mt-3">
                        <button className="btn_custom bg-color d-flex align-items-center bg-color-section btn text-light" type="button" id="getJsonSrc" onClick={fetchDataFromAPI}> Go</button>
                        <button className="btn_custom bg-color d-flex align-items-center bg-color-section btn text-light mx-1" type="button" id="getJsonSrc" >Reset</button>
                      </div>
                      <div className="col-md-4">
                        <table className="table-balance">
                          <tbody>
                            <tr>
                              <td className="text-white">Opening Balance	</td>
                              <td className="text-white">00.00</td>
                            </tr>
                            <tr>
                              <td className="text-white">Closing Balance</td>
                              <td className="text-white">00.00</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </form>
           
              </div>
            </div>
            <div className="margin-top-bottom-20">
              <div className="table-responsive">
                <table id="dataTable" className="table accountstmt" data-pagination="true" data-page-size={5} data-page-list="5, 10, All"
                  data-search="true" data-search-align="left" data-striped="true" data-show-refresh="true"
                  data-sort-name="date" data-sort-order="desc" data-search-text="Narrow results"
                >
                  <thead className="table_account display_change thead-success">
                    <tr>
                      <th className="col-xs-1" data-field="date">
                        Date
                      </th>
                      <th className="col-xs-1" data-field="date">
                        S.NO
                      </th>
                      <th className="col-xs-1" data-field="operator">
                        Credit
                      </th>
                      <th className="col-xs-2" data-field="type">
                        Debit
                      </th>
                      <th className="col-xs-5" data-field="message">
                        Balance
                      </th>
                      <th className="col-xs-5" data-field="message">
                        Remark
                      </th>
                    </tr>

                  </thead>
                  <tbody>
                    {filteredData.map((item, index) => (
                      <tr key={index}  >
                        <td>{formatDate(item.created_at)}</td>
                        <td>{index + 1}</td>
                        <td style={{ color: 'green' }}>{item.credit}</td>
                        <td style={{ color: 'red' }}>{item.debit}</td>
                        <td style={{ color: 'green' }}>{item.total_amount}</td>
                        <td className="d-flex">{item.remark}-{item.bet_rate} - <span style={{ color: item.tr_status === 'Success' ? 'green' : 'red' }}>{item.tr_status}</span></td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </main>
        </section>
      </>
    </div>
  )
}
export default Accountstatement
