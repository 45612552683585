import React, { useState, useEffect } from "react";
import Ssl from "../assets/images/secureicon.png";
import whatsappicon from "../assets/images/whatsappicon.png";
import notification from "../assets/images/notification.png";

const Footer = () => {
  const [GenralSetting, setGenralSetting] = useState([]);
  const [whatsapp, setwhatsapp] = useState([]);
  useEffect(() => {
    fetchGenralSetting();
  }, []);
  const fetchGenralSetting = () => {
    const token = localStorage.getItem("token");

    if (!token) {
      console.error("Token is missing");
      return;
    }

    const apiUrl = process.env.REACT_APP_API_URL;
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    fetch(`${process.env.REACT_APP_API_URL}general-setting`, {
      method: "GET",
      headers: headers,
    })
      .then((response) => response.json())
      .then((data) => {
        setGenralSetting(data.data);
        setwhatsapp("https://wa.me/+" + data.data.mobile);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };
  return (
    <footer className="footer">
      <div className="support">
        <div>
          <div className="w-100 text-center">
            <b>24 X 7 Support</b>
          </div>
          <div className="text-center w-100"></div>
        </div>
        <div className="footer-social">
          {/* <a href="#" > */}
          <a href={whatsapp} target="_blank">
            <img src={whatsappicon} alt="whatsapp" />
          </a>
          <a href="#">
            <img src={notification} alt="news" />
          </a>
        </div>
      </div>
      <div className="footer-menu">
        <ul>
          <li>
            <a href="/Aboutus">About Us</a>
          </li>
          <li>
            <a href="/Faq">FAQ</a>
          </li>
          <li>
            <a href="/Gamerule">Rules</a>
          </li>
          <li>
            <a href="/Termsandcondition">Terms and Conditions</a>
          </li>
          <li>
            <a href="/Responsivegaming">Responsible Gaming</a>
          </li>
        </ul>
      </div>
      <div className="footer-box">
        <div className="footer-top">
          <div className="secure-logo">
            <div>
              <img src={Ssl} alt="ssl" />
            </div>

            <div className="secure">
              <b>100% SAFE</b>
              <div>Protected connection and encrypted data.</div>
            </div>
          </div>
        </div>
        <div className="footer-bottom"></div>
      </div>
      <div className="text-center mt-1 w-100 copyright">
        © Copyright 2024. All Rights Reserved.
      </div>
    </footer>
  );
};

export default Footer;
