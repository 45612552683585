import React from 'react';
import Footer from '../Page/Footer'
const Termsandcondition = () => {
  return (
    <div>
         <div className="terms-container">
      <h1 className="terms-heading">
        Terms and Conditions of Use for Leobook - Comprehensive Betting Guidelines
      </h1>
      <p className="terms-paragraph">
        Welcome to Leobook. These Terms and Conditions govern your use of our betting platform. By accessing or using Leobook, you agree to comply with and be bound by these terms. If you do not agree with any part of these terms, you must not use our services. We may modify these terms periodically, and your continued use of the site constitutes acceptance of any changes. It is your responsibility to review these terms regularly.
      </p>
      <section className="terms-section">
        <h2>1. Acceptance of Terms</h2>
        <p>
          By accessing or using the Leobook website and services, you agree to adhere to these Terms and Conditions. If you do not agree with any part of these terms, you must refrain from using our services. We reserve the right to modify these terms at any time without prior notice, and it is your responsibility to review them regularly.
        </p>
      </section>
      <section className="terms-section">
        <h2>2. Use of Betting Services</h2>
        <p>
          Leobook provides a platform for users to place bets on various sports and events. You agree to use our services only for lawful purposes and in accordance with applicable betting regulations. Any misuse of the platform, including fraudulent activities, will result in immediate suspension or termination of your account. You must not use the platform for any illegal or unauthorized activities.
        </p>
      </section>
      <section className="terms-section">
        <h2>3. Registration and Account Management</h2>
        <p>
          To access certain features of Leobook, you must register and create an account. When registering, you agree to provide accurate, current, and complete information. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account. Notify us immediately if you suspect any unauthorized use of your account or if your account information is compromised. Leobook reserves the right to terminate accounts at our discretion.
        </p>
      </section>
      <section className="terms-section">
        <h2>4. Betting Rules and Regulations</h2>
        <p>
          All bets placed through Leobook are subject to our betting rules and regulations. These include specific conditions related to various sports and events, minimum and maximum bet limits, and eligibility criteria. It is your responsibility to familiarize yourself with these rules before placing any bets. Leobook may adjust these rules and limits at our discretion.
        </p>
      </section>
      <section className="terms-section">
        <h2>5. Payment and Withdrawals</h2>
        <p>
          All financial transactions, including deposits and withdrawals, must be processed through the methods provided on the Leobook platform. You agree to provide accurate payment information and to comply with all requirements for financial transactions. Leobook reserves the right to review and verify all transactions and to delay or refuse withdrawals if there are any discrepancies or concerns.
        </p>
      </section>
      <section className="terms-section">
        <h2>6. Responsible Gambling</h2>
        <p>
          Leobook is committed to promoting responsible gambling. We provide resources and tools to help users manage their gambling activities. You should only gamble with money you can afford to lose and seek help if you believe you may have a gambling problem. If necessary, we may suspend or close accounts for users who exhibit problematic gambling behavior.
        </p>
      </section>
      <section className="terms-section">
        <h2>7. Security and Privacy</h2>
        <p>
          We implement industry-standard security measures to protect your personal and financial information. However, no online platform is completely secure. By using our services, you acknowledge and accept the risks associated with online transactions and data breaches. Our Privacy Policy outlines how we collect, use, and protect your personal data.
        </p>
      </section>
      <section className="terms-section">
        <h2>8. Intellectual Property</h2>
        <p>
          All content, trademarks, and other intellectual property on the Leobook website are owned by Leobook or our licensors. You may not use, reproduce, or distribute any content from our website without our prior written consent. Unauthorized use of intellectual property may result in legal action.
        </p>
      </section>
      <section className="terms-section">
        <h2>9. Limitation of Liability</h2>
        <p>
          Leobook is not liable for any indirect, incidental, special, or consequential damages arising from or related to your use of our services. We do not guarantee the accuracy or completeness of any information provided on our platform. Our liability is limited to the maximum extent permitted by law. We are not responsible for any losses or damages resulting from your reliance on information or content provided by Leobook.
        </p>
      </section>
      <section className="terms-section">
        <h2>10. Termination</h2>
        <p>
          Leobook reserves the right to terminate or suspend your account at any time for any reason, including but not limited to violation of these terms, fraudulent activities, or legal compliance issues. Upon termination, you will lose access to your account and any remaining funds, unless otherwise specified.
        </p>
      </section>
      <section className="terms-section">
        <h2>11. Dispute Resolution</h2>
        <p>
          Any disputes arising from or related to these Terms and Conditions or your use of Leobook services will be resolved through binding arbitration in accordance with the rules of the jurisdiction in which Leobook operates. The arbitration process will be conducted in the English language, and the decision of the arbitrator will be final and binding.
        </p>
      </section>
      <section className="terms-section">
        <h2>12. Governing Law</h2>
        <p>
          These terms are governed by and construed in accordance with the laws of the jurisdiction in which Leobook operates. Any disputes arising under these terms will be subject to the exclusive jurisdiction of the courts in that jurisdiction. If any provision of these terms is found to be invalid or unenforceable, the remaining provisions will remain in full force and effect.
        </p>
      </section>
      <section className="terms-section">
        <h2>13. Changes to Terms</h2>
        <p>
          Leobook reserves the right to modify these Terms and Conditions at any time. We will notify users of any significant changes by posting the updated terms on our website. It is your responsibility to review these terms periodically. Continued use of our services after any changes constitutes acceptance of the new terms.
        </p>
      </section>
      <section className="terms-section">
        <h2>14. Contact Information</h2>
        <p>
          If you have any questions, concerns, or feedback regarding these Terms and Conditions or our services, please contact us at support@leobook.com. We will make reasonable efforts to address your inquiries and provide assistance.
        </p>
      </section>
      <section className="terms-section">
        <h2>15. Additional Terms</h2>
        <p>
          In addition to these Terms and Conditions, you may be subject to additional terms and conditions related to specific promotions, contests, or other features of the Leobook platform. These additional terms will be provided to you at the time of participation and will form part of your agreement with Leobook.
        </p>
      </section>
      <section className="terms-section">
        <h2>16. User Conduct</h2>
        <p>
          You agree to use the Leobook platform in a manner that is respectful, legal, and in accordance with these terms. Prohibited conduct includes, but is not limited to, harassment, abusive language, and attempting to exploit or disrupt our platform. We reserve the right to take appropriate action, including suspending or terminating your account, if you engage in such conduct.
        </p>
      </section>
      <section className="terms-section">
        <h2>17. Force Majeure</h2>
        <p>
          Leobook is not liable for any failure or delay in performing our obligations under these Terms and Conditions due to circumstances beyond our reasonable control, including but not limited to natural disasters, acts of terrorism, or other events of force majeure.
        </p>
      </section>
      <section className="terms-section">
        <h2>18. Third-Party Links</h2>
        <p>
          Our website may contain links to third-party websites or services that are not owned or controlled by Leobook. We do not assume any responsibility for the content, privacy policies, or practices of any third-party websites. You access third-party sites at your own risk, and you should review their terms and conditions before interacting with them.
        </p>
      </section>
      <section className="terms-section">
        <h2>19. User Feedback</h2>
        <p>
          Any feedback, suggestions, or ideas you provide to Leobook regarding our services will be considered non-confidential and non-proprietary. We may use such feedback to improve our services without any obligation to you.
        </p>
      </section>
      <section className="terms-section">
        <h2>20. Indemnification</h2>
        <p>
          You agree to indemnify and hold harmless Leobook, its affiliates, officers, directors, employees, and agents from and against any claims, liabilities, damages, losses, and expenses arising from your use of our services or your violation of these terms.
        </p>
      </section>
      <section className="terms-section">
        <h2>21. No Waiver</h2>
        <p>
          The failure of Leobook to enforce any provision of these Terms and Conditions will not be deemed a waiver of such provision or of the right to enforce it at a later time. No waiver of any term or condition shall be deemed a further or continuing waiver of any other term or condition.
        </p>
      </section>
      <section className="terms-section">
        <h2>22. Entire Agreement</h2>
        <p>
          These Terms and Conditions, along with our Privacy Policy, constitute the entire agreement between you and Leobook regarding the use of our services and supersede all prior agreements and understandings.
        </p>
      </section>
      <section className="terms-section">
        <h2>23. Additional Policies</h2>
        <p>
          Additional policies and procedures may apply to specific aspects of our services, such as promotions, bonuses, or contests. These policies will be provided to you at the relevant time and will be incorporated into your agreement with Leobook.
        </p>
      </section>
      <section className="terms-section">
        <h2>24. Platform Availability</h2>
        <p>
          We strive to ensure that the Leobook platform is available at all times. However, we do not guarantee that the platform will be free of interruptions, errors, or outages. We may perform maintenance or updates that could temporarily affect the availability of our services.
        </p>
      </section>
      <section className="terms-section">
        <h2>25. Data Protection</h2>
        <p>
          Leobook is committed to protecting your personal data in accordance with applicable data protection laws. Our Privacy Policy provides detailed information about how we collect, use, and safeguard your personal information. By using our services, you consent to our data practices as described in the Privacy Policy.
        </p>
      </section>
      <section className="terms-section">
        <h2>26. Electronic Communications</h2>
        <p>
          By using the Leobook platform, you consent to receive electronic communications from us, including emails, notifications, and updates. These communications will be considered as being in writing and may include information about your account, our services, and promotions.
        </p>
      </section>
      <section className="terms-section">
        <h2>27. Site Content</h2>
        <p>
          All content on the Leobook website, including text, graphics, logos, and software, is the property of Leobook or its content suppliers and is protected by copyright and other intellectual property laws. Unauthorized use of the content may result in legal action.
        </p>
      </section>
      <section className="terms-section">
        <h2>28. Changes to Service</h2>
        <p>
          Leobook reserves the right to modify or discontinue any aspect of our services at any time. We will provide notice of significant changes, but we are not obligated to continue offering any specific features or services.
        </p>
      </section>
      <section className="terms-section">
        <h2>29. Betting Limits</h2>
        <p>
          Leobook may set limits on the amount you can bet or withdraw. These limits are subject to change and may vary depending on your account status, betting history, and other factors. It is your responsibility to manage your betting activity within these limits.
        </p>
      </section>
      <section className="terms-section">
        <h2>30. Affiliate Programs</h2>
        <p>
          If you participate in any affiliate programs or referral bonuses offered by Leobook, you must comply with the specific terms and conditions associated with those programs. Failure to do so may result in disqualification from the program and forfeiture of any rewards.
        </p>
      </section>
      <section className="terms-section">
        <h2>31. System Requirements</h2>
        <p>
          To use the Leobook platform, you must have compatible hardware and software, including a stable internet connection. We are not responsible for any issues related to your system’s compatibility or connectivity.
        </p>
      </section>
      <section className="terms-section">
        <h2>32. User Conduct</h2>
        <p>
          You agree to conduct yourself in a manner that is respectful and lawful. Prohibited conduct includes spamming, cheating, or any form of dishonest behavior. Violations may result in account suspension or termination.
        </p>
      </section>
      <section className="terms-section">
        <h2>33. Account Verification</h2>
        <p>
          Leobook may require you to verify your identity and provide additional documentation before processing certain transactions. This verification process is intended to prevent fraud and ensure compliance with applicable regulations.
        </p>
      </section>
      <section className="terms-section">
        <h2>34. Betting Disputes</h2>
        <p>
          In the event of a dispute regarding a bet, Leobook’s decision will be final and binding. We will review the dispute in accordance with our internal procedures and provide a resolution based on the information available.
        </p>
      </section>
      <section className="terms-section">
        <h2>35. User Responsibilities</h2>
        <p>
          You are responsible for ensuring that all bets you place are accurate and in accordance with the betting rules. Leobook will not be liable for any errors or issues arising from incorrect bets placed by you.
        </p>
      </section>
      <section className="terms-section">
        <h2>36. Tax Responsibilities</h2>
        <p>
          Any taxes or other financial obligations related to your winnings or betting activities are your responsibility. Leobook will not be responsible for any tax liabilities or other financial obligations incurred by you as a result of using our services.
        </p>
      </section>
      <section className="terms-section">
        <h2>37. Promotional Offers</h2>
        <p>
          Promotional offers and bonuses provided by Leobook are subject to additional terms and conditions. These terms will be communicated to you at the time of the offer, and it is your responsibility to review and understand them before participating.
        </p>
      </section>
      <section className="terms-section">
        <h2>38. Data Retention</h2>
        <p>
          We may retain your personal data for as long as necessary to fulfill the purposes for which it was collected, including legal and regulatory requirements. Data retention periods are outlined in our Privacy Policy.
        </p>
      </section>
      <section className="terms-section">
        <h2>39. Service Interruptions</h2>
        <p>
          Leobook may experience interruptions or downtime due to maintenance, upgrades, or other factors. We will make reasonable efforts to minimize the impact on your experience, but we are not liable for any losses or inconveniences caused by such interruptions.
        </p>
      </section>
      <section className="terms-section">
        <h2>40. Governing Language</h2>
        <p>
          These Terms and Conditions are provided in English. In the event of any discrepancies between translations, the English version will prevail.
        </p>
      </section>
      <section className="terms-section">
        <h2>41. User Surveys</h2>
        <p>
          Leobook may conduct surveys or collect feedback from users to improve our services. Participation in surveys is voluntary, and any information provided will be used in accordance with our Privacy Policy.
        </p>
      </section>
      <section className="terms-section">
        <h2>42. Third-Party Providers</h2>
        <p>
          We may engage third-party providers to offer certain services or features on our platform. These third parties are subject to their own terms and conditions, and we are not responsible for their actions or omissions.
        </p>
      </section>
      <section className="terms-section">
        <h2>43. Platform Integrity</h2>
        <p>
          Leobook employs various measures to ensure the integrity of our betting platform. However, we cannot guarantee that the platform will be free from errors or vulnerabilities. It is your responsibility to use the platform safely and report any issues you encounter.
        </p>
      </section>
      <section className="terms-section">
        <h2>44. Account Accessibility</h2>
        <p>
          You are responsible for ensuring that you can access your Leobook account and use our services without issues. If you encounter problems accessing your account, please contact our support team for assistance.
        </p>
      </section>
      <section className="terms-section">
        <h2>45. Bonus Terms</h2>
        <p>
          Bonuses offered by Leobook are subject to specific terms and conditions, including wagering requirements and eligibility criteria. These terms will be communicated to you when you receive a bonus, and it is your responsibility to understand and comply with them.
        </p>
      </section>
      <section className="terms-section">
        <h2>46. User Account History</h2>
        <p>
          You can access your account history, including transaction details and betting activity, through the Leobook platform. It is your responsibility to review your account history regularly to ensure its accuracy.
        </p>
      </section>
      <section className="terms-section">
        <h2>47. System Errors</h2>
        <p>
          In the event of a system error or technical malfunction, Leobook reserves the right to void bets, transactions, or other actions that were affected by the error. We will make reasonable efforts to rectify any issues and ensure fair play.
        </p>
      </section>
      <section className="terms-section">
        <h2>48. User Data</h2>
        <p>
          Leobook may collect and process user data as described in our Privacy Policy. By using our services, you consent to the collection and use of your data in accordance with our Privacy Policy.
        </p>
      </section>
      <section className="terms-section">
        <h2>49. Platform Updates</h2>
        <p>
          We may update the Leobook platform periodically to enhance functionality, security, and user experience. These updates may include changes to features, design, or user interface. We will make reasonable efforts to communicate significant updates to you.
        </p>
      </section>
      <section className="terms-section">
        <h2>50. Contact Us</h2>
        <p>
          For any questions, concerns, or feedback regarding these Terms and Conditions or any aspect of the Leobook platform, please reach out to us at support@leobook.com. Our support team is available to assist you and address any issues you may have.
        </p>
      </section>
    </div>
    <Footer/>
    </div>
  );
};

export default Termsandcondition;
