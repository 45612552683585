import React, { useState, useEffect } from 'react';
import Slider from "react-slick";
import axios from 'axios';

export default function Sliderindex() {
    const [sliderData, setSliderData] = useState([]);
    const [baseUrl, setBaseUrl] = useState('');
    const token = localStorage.getItem("token");

    const settings = {
        dots: false,
        infinite: true,
        arrows: false,
        autoplay: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    useEffect(() => {
        fetchSlider();
    }, []);

    const fetchSlider = async () => {
        try {
            const url = `https://leobook.in/Admin/api/v1/slider`;
            const config = {
                method: "GET",
                url: url,
                headers: {
                    "Content-Type": "application/json",
                     Authorization: `Bearer ${token}`,
                },
            };

            const response = await axios(config);
            setSliderData(response.data.data); 
            setBaseUrl(response.data.base_url); 
        } catch (error) {
            console.error("Error fetching slider data:", error);
        }
    };

    return (
        <div className="container-fluid px-0">
            <Slider {...settings}>
                {sliderData && sliderData.map((item, index) => (
                    <div key={index}>
                        <img
                            src={`${baseUrl}/${item.images}`}
                            className="img-fluid"
                            alt={`Slide ${index + 1}`}
                        />
                    </div>
                ))}
            </Slider>
        </div>
    );
}
