import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { PiTelevisionSimpleFill } from "react-icons/pi";
import { Badge, Button, Modal, Form } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import video from "../assets/images/video.mp4";
import { Player } from "video-react";
import "video-react/dist/video-react.css";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { FaAngleDown } from "react-icons/fa6";
import loading from "../assets/images/loading-gif.gif";
import Ssl from "../assets/images/secureicon.png";
import { FaTv } from "react-icons/fa";

import whatsappicon from "../assets/images/whatsappicon.png";
import notification from "../assets/images/notification.png";
import { fetchwalletamount } from "../Page/common";
import Axios from "axios";
import Footer from "../Page/Footer";
import { IoClose } from "react-icons/io5";

import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

export default function Bettingpage() {
  const [tvshow, setTvshow] = useState(false);
  const [placebetDisable, setPlacebetDisable] = useState(false);
  const [buttonValues, setButtonValues] = useState([]);
  const [isDisabledSubmit, setIsDisabledSubmit] = useState(false);

  const [teamExposer1, setteamExposer1] = useState([]);
  const [teamExposerAll, setteamExposerAll] = useState([]);
  const [teamExposerAllBook, setteamExposerAllBook] = useState([]);
  const [GenralSetting, setGenralSetting] = useState([]);
  const [teamExposer2, setteamExposer2] = useState([]);
  const [teamExposer3, setteamExposer3] = useState([]);

  const [teamExposerTeamid1, setteamExposerTeamid1] = useState([]);
  const [teamExposerTeamid2, setteamExposerTeamid2] = useState([]);
  const [teamExposerTeamid3, setteamExposerTeamid3] = useState([]);
  const seriesId = localStorage.getItem("seriesId");
  // const event_id = localStorage.getItem("event_id");
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const event_id = queryParams.get("eventid");
  const matchid = queryParams.get("matchid");
  const ptid = queryParams.get("id");
  const name = queryParams.get("name");
  const userid = localStorage.getItem("userid");
  // const name = localStorage.getItem("name");
  // const id = localStorage.getItem("id");
  const token = localStorage.getItem("token");
  const [isButtonVisible, setIsButtonVisible] = useState(true);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [togglebtn, setTogglebtn] = useState(false);
  const [data, setData] = useState([]);
  const [selectedAmount, setSelectedAmount] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [stackValue, setStackValue] = useState("");
  const [selectedname, setSelectedname] = useState("");
  const [showSection, setShowSection] = useState(false);
  const [fancydata, setFancyData] = useState([]);
  const [errors, setErrors] = useState({});
  const [selectedfid, setSelectedfid] = useState("");
  const [selectedEid, setSelectedEid] = useState("");
  const [selectedbettype, setSelectedbettype] = useState("");
  const [oddValue, setoddValue] = useState("");
  const [stackvaluetotal, setstackvaluetotal] = useState("");
  const [myBets, setMyBets] = useState([]);
  const [showBets, setShowBets] = useState(false);
  const [data1, setData1] = useState(null);
  const [show1, setShow1] = useState(false);
  const [bethide, setBethide] = useState(false);
  const handleClose1 = () => {
    setShow1(false);
    setBethide(false);
  };

  const handleShow1 = () => setShow1(true);
  const navigate = useNavigate();
  const [bettingvalue, setBettingvalue] = useState("");
  const valuebetting = (value) => {
    setBettingvalue(value);
  };

  ///////////pagination////////////////////
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = myBets.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(myBets.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  /////////////////////////////////////////////
  const [stackValueteam, setStackValueteam] = useState("");
  const [selectednameteam, setSelectednameteam] = useState("");
  const [MatchType, setMatchType] = useState("");
  const [showSectionteam, setShowSectionteam] = useState(false);
  const [fancydatateam, setFancyDatateam] = useState([]);

  const [fancyDatateam12, setFancyDatateam12] = useState([]);
  const [BookMakerList, setBookMakerList] = useState([]);
  const [FancyDatateamName, setFancyDatateamName] = useState([]);
  const [FancyDatateamName1, setFancyDatateamName1] = useState([]);
  const [FancyDatateamName2, setFancyDatateamName2] = useState([]);
  const [fancydatateam1, setFancyDatateam1] = useState([]);
  const [fancydatateam2, setFancyDatateam2] = useState([]);
  const [fancydatateam3, setFancyDatateam3] = useState([]);
  const [selectedfidteam, setSelectedfidteam] = useState("");
  const [selectedEidteam, setSelectedEidteam] = useState("");
  const [selectedbettypeteam, setSelectedbettypeteam] = useState("");
  const [oddValueteam, setoddValueteam] = useState("");
  const [teamid, setteamid] = useState("");
  const [marketid, setmarketid] = useState("");
  const [stackvaluetotalteam, setstackvaluetotalteam] = useState("");
  const [gameStatus, setGameStatus] = useState("");

  const [teamexposercurrent1, setteamexposercurrent1] = useState(0);
  const [teamexposercurrent2, setteamexposercurrent2] = useState(0);

  const [teamexposercurrent1Name, setteamexposercurrent1Name] = useState("");
  const [teamexposercurrent2Name, setteamexposercurrent2Name] = useState("");

  const [currentExpbgColor1, setcurrentExpbgColor1] = useState();
  const [currentExpbgColor2, setcurrentExpbgColor2] = useState();

  const [bettingvalueteam, setBettingvalueteam] = useState("");

  const [matchOddsShowCurrentExp, setmatchOddsShowCurrentExp] = useState(false);
  const [bookMakerShowCurrentExp, setbookMakerShowCurrentExp] = useState(false);

  const valuebettingteam = (value) => {
    // alert(MatchType);
    if (MatchType == "match_odds") {
      if (bgColor == "#72bbef") {
        // back
        var abc =
          parseFloat(value) * parseFloat(stackValueteam) - parseFloat(value);
        setteamexposercurrent1Name(selectednameteam);
        setteamexposercurrent2Name("none");

        setteamexposercurrent1(parseInt(abc));
        setteamexposercurrent2(parseInt(value));

        const color1 = "#00ff00";
        const color2 = "#ff0000";
        setcurrentExpbgColor1(color1);
        setcurrentExpbgColor2(color2);
      } else {
        // lay
        // console.warn("tytytytytytyt", value + "," + stackValueteam + "," + value);
        var abc =
          parseFloat(value) * parseFloat(stackValueteam) - parseFloat(value);
        setteamexposercurrent1Name(selectednameteam);
        setteamexposercurrent2Name("none");

        setteamexposercurrent1(parseInt(abc));
        setteamexposercurrent2(parseInt(value));

        const color1 = "#ff0000";
        const color2 = "#00ff00";
        setcurrentExpbgColor1(color1);
        setcurrentExpbgColor2(color2);
      }
      setmatchOddsShowCurrentExp(true);
      setbookMakerShowCurrentExp(false);
    } else {
      if (bgColor == "#72bbef") {
        // back
        var abc = (parseFloat(value) * parseFloat(stackValueteam)) / 100;
        setteamexposercurrent1Name(selectednameteam);
        setteamexposercurrent2Name("none");

        setteamexposercurrent1(parseInt(abc));
        setteamexposercurrent2(parseInt(value));

        const color1 = "#00ff00";
        const color2 = "#ff0000";
        setcurrentExpbgColor1(color1);
        setcurrentExpbgColor2(color2);
      } else {
        // lay
        // console.warn(
        //   "tytytytytytyt",
        //   value + "," + stackValueteam + "," + value
        // );
        var abc = (parseFloat(value) * parseFloat(stackValueteam)) / 100;
        setteamexposercurrent1Name(selectednameteam);
        setteamexposercurrent2Name("none");

        setteamexposercurrent1(parseInt(abc));
        setteamexposercurrent2(parseInt(value));
        // alert(abc);
        const color1 = "#ff0000";
        const color2 = "#00ff00";
        setcurrentExpbgColor1(color1);
        setcurrentExpbgColor2(color2);
      }
      setbookMakerShowCurrentExp(true);
      setmatchOddsShowCurrentExp(false);
    }

    setBettingvalueteam(value);
  };

  const inputchangeteam = (event) => {
    const { value } = event.target;
    if (MatchType == "match_odds") {
      if (bgColor == "#72bbef") {
        // back
        var abc =
          parseFloat(value) * parseFloat(stackValueteam) - parseFloat(value);
        setteamexposercurrent1Name(selectednameteam);
        setteamexposercurrent2Name("none");

        setteamexposercurrent1(parseInt(abc));
        setteamexposercurrent2(parseInt(value));

        const color1 = "#00ff00";
        const color2 = "#ff0000";
        setcurrentExpbgColor1(color1);
        setcurrentExpbgColor2(color2);
      } else {
        // lay
        var abc =
          parseFloat(value) * parseFloat(stackValueteam) - parseFloat(value);
        setteamexposercurrent1Name(selectednameteam);
        setteamexposercurrent2Name("none");

        setteamexposercurrent1(parseInt(abc));
        setteamexposercurrent2(parseInt(value));

        const color1 = "#ff0000";
        const color2 = "#00ff00";
        setcurrentExpbgColor1(color1);
        setcurrentExpbgColor2(color2);
      }
      setmatchOddsShowCurrentExp(true);
      setbookMakerShowCurrentExp(false);
    } else {
      if (bgColor == "#72bbef") {
        // back
        var abc = (parseFloat(value) * parseFloat(stackValueteam)) / 100;
        setteamexposercurrent1Name(selectednameteam);
        setteamexposercurrent2Name("none");

        setteamexposercurrent1(parseInt(abc));
        setteamexposercurrent2(parseInt(value));

        const color1 = "#00ff00";
        const color2 = "#ff0000";
        setcurrentExpbgColor1(color1);
        setcurrentExpbgColor2(color2);
      } else {
        // lay
        var abc = (parseFloat(value) * parseFloat(stackValueteam)) / 100;
        setteamexposercurrent1Name(selectednameteam);
        setteamexposercurrent2Name("none");

        setteamexposercurrent1(parseInt(abc));
        setteamexposercurrent2(parseInt(value));

        const color1 = "#ff0000";
        const color2 = "#00ff00";
        setcurrentExpbgColor1(color1);
        setcurrentExpbgColor2(color2);
      }
      setbookMakerShowCurrentExp(true);
      setmatchOddsShowCurrentExp(false);
    }

    setBettingvalueteam(value);
    // setBettingvalue(e.target.value);
  };
  // \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\

  const tvshowhandle = () => {
    setTvshow(!tvshow);
  };

  ///////////////////////////////////////////////////
  const inputchange = (event) => {
    const { value } = event.target;
    setBettingvalue(value);

    // setBettingvalue(e.target.value);
  };
  const [isVisible, setIsVisible] = useState(true);

  // Toggle visibility
  const toggleVisibility = () => {
    setIsVisible((prevState) => !prevState);
  };
  const [mybet, setMybet] = useState(true);

  // Toggle visibility
  const togglebet = () => {
    setMybet((prevState) => !prevState);
  };

  const [bgColor, setBgcolor] = useState();

  const color2 = "#72bbef";
  const color1 = "#f994ba";

  useEffect(() => {
    fetchMyBetsData();
  }, []);
  const fetchMyBetsData = () => {
    const token = localStorage.getItem("token");

    if (!token) {
      console.error("Token is missing");
      return;
    }

    const apiUrl = process.env.REACT_APP_API_URL;
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    fetch(`${process.env.REACT_APP_API_URL}my-bet`, {
      method: "GET",
      headers: headers,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status_code === 1) {
          setMyBets(data.data);
        } else {
          if (data.message === "Unauthorized") {
            localStorage.clear();
            navigate("/");
          } else {
            console.error("API request failed:", data.error_message);
          }
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };
  useEffect(() => {
    const getButtonValues = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}get-button-value`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.ok) {
          const rawResponse = await response.json();

          const cleanedData = rawResponse.data.map((item) =>
            item.replace(/"/g, "")
          );
          const cleanedResponse = { ...rawResponse, data: cleanedData };
          if (cleanedResponse.status_code === 1) {
            setButtonValues(cleanedResponse.data);
          } else {
            console.error("Invalid data format:", cleanedResponse);
          }
        } else {
          console.error("Failed to fetch button values");
        }
      } catch (error) {
        console.error("Error fetching button values:", error);
      }
    };

    getButtonValues();
  }, []);

  const handleValueClick = (
    color,
    value,
    name,
    fancy_id,
    event,
    betType,
    lay_line,
    lay_price
  ) => {
    const isMobile = window.matchMedia("(max-width: 991px)").matches;
    if (isMobile) {
      handleShow1();
    }
    setStackValueteam("");
    setBgcolor(color);
    setBethide(true);
    const concatenatedFancyId = `${event_id}-${fancy_id}`;
    // const concatenatedFancyId = `${fancy_id}`;
    setStackValue(value);
    setSelectedname(name);
    setSelectedfid(concatenatedFancyId);
    setSelectedEid(event_id);
    setSelectedbettype(betType);
    setoddValue(lay_line);
    const formattedLayPrice = lay_price.toString().replace(/\.00$/, "");
    setstackvaluetotal(formattedLayPrice);
    setShowSection(true);
  };

  const handleValueClickTeam = (
    color,
    value,
    name,
    fancy_id,
    market_id,
    betType,
    lay_line,
    Matchtype
    // event_id,
    // lay_price,
    // team_id
  ) => {
    const isMobile = window.matchMedia("(max-width: 991px)").matches;
    if (isMobile) {
      handleShow1();
    }

    setteamexposercurrent1(0);
    setteamexposercurrent2(0);

    setBettingvalueteam("");

    const queryParams = new URLSearchParams(location.search);
    const matchid = queryParams.get("matchid");
    const event_id = queryParams.get("eventid");
    setBgcolor(color);
    setBethide(true);
    const concatenatedFancyId = `${event_id}-${fancy_id}`;
    setSelectedname("");
    setStackValueteam(value);
    setMatchType(Matchtype);
    // alert(name);
    setSelectednameteam(name);
    setSelectedfidteam(concatenatedFancyId);
    setSelectedEidteam(event_id);
    setSelectedbettypeteam(betType);
    setoddValueteam(lay_line);
    setteamid(fancy_id);
    setmarketid(matchid);
    const formattedLayPrice = lay_line;
    // const formattedLayPrice = lay_price.toString().replace(/\.00$/, "");
    setstackvaluetotalteam(formattedLayPrice);

    setShowSectionteam(true);
  };

  useEffect(() => {
    const generateRandomData = () => {
      return {
        LayPrice1: (Math.random() * (4 - 2) + 2).toFixed(1),
        LaySize1: Math.floor(Math.random() * 2000) + 500,
        BackPrice1: (Math.random() * (3.5 - 2.5) + 2.5).toFixed(1),
        BackSize1: Math.floor(Math.random() * 2500) + 1000,
        GameStatus: gameStatus,
      };
    };

    const queryParams = new URLSearchParams(location.search);
    const matchid = queryParams.get("matchid");
    fetchDatamatchodds(matchid);
    fetchDatabookmaker(matchid);
    fetchExposerteampls();
    const Placedata = {
      // betSlip_odds: 2.5,
      // betSlip_stake: 100,
      // bet_on: "Team A to win",
      // total: 250,
      home_team: {
        team_id: 1,
        team_name: "Team A",
        RunnerName: "Team A",
        market_id: 456,
        SelectionId: 101,
        event_id: 123,
        ...generateRandomData(),
      },
      away_team: {
        team_id: 2,
        team_name: "Team B",
        RunnerName: "Team B",
        market_id: 456,
        SelectionId: 102,
        event_id: 123,
        ...generateRandomData(),
      },
      event_id: 123,
      sport_id: 789,
      fancy_id: 0,
    };

    const data = [Placedata.home_team, Placedata.away_team].map((item) => ({
      RunnerName: item.RunnerName,
      team_id: item.team_id,
      LayPrice1: item.LayPrice1,
      LaySize1: item.LaySize1,
      BackPrice1: item.BackPrice1,
      BackSize1: item.BackSize1,
      GameStatus: item.GameStatus,
      SelectionId: item.SelectionId,
      event_id: item.event_id,
      market_id: item.market_id,
    }));
    setFancyDatateam(data);

    const intervalId = setInterval(() => {
      setGameStatus((prevStatus) => (prevStatus === "" ? "Ball Running" : ""));
    }, 10000);

    return () => clearInterval(intervalId); // Clean up the interval on component unmount
  }, [gameStatus, location]);

  const fetchDatamatchodds = async (matchid) => {
    fetchDatabookmaker(matchid);
    const token = localStorage.getItem("token");
    const response1 = await fetch(
      `${process.env.REACT_APP_API_URL}get-market-teams/${matchid}`,
      {
        method: "get",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (!response1.ok) {
      throw new Error("Network response was not ok");
    }

    const result101 = await response1.json();
    let marketarr = [];
    // fetchExposerteampls();
    const allSeriesData = await Promise.all(
      result101.data.map(async (match) => {
        const response = await fetch(
          `https://apileo.leobook.in/get-match-odds-list?id=${match.market_id}`, // Assuming match.market_id is the intended id
          {
            method: "GET",
          }
        );
        const result21 = await response.json();
        // console.warn("hreeeeeeeeeeeee", result21);
        var result210 =
          '[{"marketId":"1.232257782","updateTime":"2024-08-30T12:55:47.140445+02:00","status":"OPEN","inplay":true,"totalMatched":8764024.5,"runners":[{"selectionId":10301,"handicap":0,"status":"ACTIVE","lastPriceTraded":1.50,"totalMatched":7701128.17,"adjustmentFactor":0,"ex":{"availableToBack":[{"price":1.49,"size":3492.06},{"price":1.31,"size":14989.07},{"price":1.3,"size":28040.97}],"availableToLay":[{"price":1.50,"size":5581.64},{"price":1.34,"size":6758.26},{"price":1.35,"size":6853.03}]}},{"selectionId":7337,"handicap":0,"status":"ACTIVE","lastPriceTraded":11.5,"totalMatched":568806.51,"adjustmentFactor":0,"ex":{"availableToBack":[{"price":1.53,"size":11.15},{"price":11,"size":385.74},{"price":10,"size":402.47}],"availableToLay":[{"price":1.54,"size":543.47},{"price":12.5,"size":433.57},{"price":13,"size":110.97}]}},{"selectionId":60443,"handicap":0,"status":"inACTIVE","lastPriceTraded":6.4,"totalMatched":494089.82,"adjustmentFactor":0,"ex":{"availableToBack":[{"price":6.4,"size":725.45},{"price":6.2,"size":1158.89},{"price":6,"size":3567.15}],"availableToLay":[{"price":6.6,"size":1674.53},{"price":6.8,"size":1262.05},{"price":7,"size":728.82}]}}]}]';
        const resd = JSON.parse(result21);
        let resdata = {};
        let inplay = false;
        let status = "CLOSE";
        if (resd.length > 0) {
          inplay = resd[0].inplay;
          status = resd[0].status;
        }

        // let expamt = 0;
        // if (match.team_id == teamExposerTeamid1) {
        //   expamt = teamExposer1;
        // }
        // if (match.team_id == teamExposerTeamid2) {
        //   expamt = teamExposer2;
        // }
        // if (match.team_id == teamExposerTeamid3) {
        //   expamt = teamExposer3;
        // }

        resdata = {
          market_id: match.market_id,
          team_id: match.team_id,
          team_name: match.team_name,
          // exp: expamt,
          market_name: match.market_name,
          inplay: inplay,
          status: status,
          series: [],
        };

        if (resd.length > 0) {
          const findRunnerBySelectionId = (selectionId) => {
            if (resd.length === 0 || !resd[0].runners) return null;
            // Find the runner with the given selectionId
            return resd[0].runners.find(
              (runner) => runner.selectionId == selectionId
            );
          };
          // Example usage: Find runner with selectionId 7461
          // console.warn(match.team_id);
          var tramid = match.team_id;
          const runner = findRunnerBySelectionId(tramid);

          resdata.series.push(runner);
        }
        return resdata;
      })
    );
    setFancyDatateam12(allSeriesData);

    // const data = result.map((item) => ({
    //   RunnerName: item.marketId,
    // }));
  };

  const fetchDatabookmaker = async (matchid) => {
    const token = localStorage.getItem("token");
    const response1 = await fetch(
      `${process.env.REACT_APP_API_URL}get-market-teams/${matchid}`,
      {
        method: "get",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (!response1.ok) {
      throw new Error("Network response was not ok");
    }

    // alert("opppassssp");
    const result101 = await response1.json();
    let marketarr = [];
    console.warn("op", result101);
    // fetchExposerteampls();
    const allSeriesData = await Promise.all(
      result101.data.map(async (match, index) => {
        const response = await fetch(
          `https://apileo.leobook.in/get-book-maker-list?id=${event_id}`, // Assuming match.market_id is the intended id
          {
            method: "GET",
          }
        );
        const result21 = await response.json();
        // alert("op");
        var result210 =
          '[{"mid":8269933091928,"remark":"","remark1":"","min":100,"max":100000,"sid":1,"nat":"Namibia","s":"ACTIVE","sr":1,"gtype":"Match1","utime":"0","b1":1000,"bs1":25000,"l1":0,"ls1":0,"b2":0,"bs2":0,"b3":0,"bs3":0,"l2":0,"ls2":0,"l3":0,"ls3":0,"b1s":"True","b2s":"False","b3s":"False","l1s":"True","l2s":"False","l3s":"False"},{"mid":8269933091928,"remark":"","remark1":"","min":100,"max":100000,"sid":2,"nat":"USA","s":"ACTIVE","sr":2,"gtype":"Match1","utime":"0","b1":1.08,"bs1":100000,"l1":1.09,"ls1":100000,"b2":0,"bs2":0,"b3":0,"bs3":0,"l2":0,"ls2":0,"l3":0,"ls3":0,"b1s":"True","b2s":"False","b3s":"False","l1s":"True","l2s":"False","l3s":"False"}]';
        const resd = JSON.parse(result21);
        // setBookMakerList(resd);
        console.warn("hreeeeeeeeeeeee", resd);
        let resdata = {};

        resdata = {
          market_id: match.market_id,
          team_id: match.team_id,
          team_name: match.team_name,
          market_name: match.market_name,
          series: [],
        };

        if (resd.length > 0) {
          const findRunnerBySelectionId = (team_name, index) => {
            if (resd.length === 0 || !resd[index]) return null;
            // Find the runner with the given selectionId
            console.warn(team_name, resd[index].nat);
            if (resd[index].nat == team_name) {
              // console.warn("opp", resd);
              var rt = {
                // status: resd[index].gstatus,
                odds: resd[index],
              };
              return rt;
            }
            // return resd[index].odds.find(
            //   (odds) =>
            // );
          };
          // Example usage: Find runner with selectionId 7461
          var team_name = match.team_name;
          const runner = findRunnerBySelectionId(team_name, index);
          console.warn("io", runner);
          resdata.series.push(runner);
        }
        return resdata;
      })
    );
    console.warn("iooooooooooo", allSeriesData);
    setBookMakerList(allSeriesData);

    // const data = result.map((item) => ({
    //   RunnerName: item.marketId,
    // }));
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://apileo.leobook.in/get-fancy-list?id=${event_id}`,

          {
            method: "get",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const result = await response.json();
        setFancyData(result);
        const data = result.map((item) => ({
          RunnerName: item.name,
          LayPrice1: item.lay_price,
          LaySize1: item.lay_line,
          BackPrice1: item.back_price,
          BackSize1: item.back_line,
          GameStatus: item.fc_status,
          SelectionId: item.fancy_id,
          event_id: item.event_id,
        }));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
    const intervalId = setInterval(fetchData, 2000);
    return () => clearInterval(intervalId);
  }, [event_id]);
  const onlyData = fancydata.filter((item) => item.RunnerName.includes("Only"));
  const ballbyball = fancydata.filter((item) => item.RunnerName.includes("."));
  const fetchdatamatchone = fancydata.filter(
    (item) =>
      !item.RunnerName.includes("Only") && !item.RunnerName.includes(".")
  );

  // const fetchdatamatch = Array.isArray(fancydata)
  //   ? fancydata.filter(
  //       (item) => <></>
  //       //  !item.RunnerName.includes('Only') && !item.RunnerName.includes('.')
  //     )
  //   : [];
  const handlePlaceBetteam = async (e) => {
    setIsDisabledSubmit(true);
    e.preventDefault();
    // setIsButtonDisabledteam(true);
    // setIsButtonVisibleteam(false);
    setErrors({});
  
    if (!bettingvalueteam) {
      setErrors({ bettingvalueteam: "" });

      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please enter an amount!",
        // timer: 1000,
      });
      setTimeout (() =>{

        setIsDisabledSubmit(false);
      },3000)
      return false;
    }
  ;
    const token = localStorage.getItem("token");
    const formData = new FormData();
    formData.append("fancy_id", selectedfidteam);
    formData.append("betSlip_stake", bettingvalueteam);
    formData.append("betSlip_odds", stackValueteam);
    // formData.append('stake', '100');
    formData.append("bet_on", selectedbettypeteam);
    formData.append("user_id", userid);
    formData.append("total", (stackValueteam * bettingvalueteam).toFixed(2));
    formData.append("event_id", selectedEidteam);
    formData.append("team", selectednameteam);
    formData.append("sport_id", "4");
    formData.append("team_id", teamid);
    formData.append("market_id", marketid);
    formData.append("MatchType", MatchType);
    // ,team_id,,market_id,
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    };

    try {
      const response = await Axios.post(
        `${process.env.REACT_APP_API_URL}place-betpost`,
        formData,
        { headers }
      );

      console.log("API Response:", response.data);
      setIsDisabledSubmit(false);
      if (response.data.status_code === 1) {
        // Success case
        // fetchDatamatchodds();
        fetchExposerteampls();
        setteamexposercurrent1(0);
        setteamexposercurrent2(0);
        navigate(
          `/Bettingpage?id=${ptid}&eventid=${event_id}&matchid=${matchid}&name=${name}&reload=${new Date().getTime()}`
        );
        Swal.fire({
          icon: "success",
          title: "Success",
          text: response.data.message,

          showConfirmButton: false,
          timer: 1000,
        });
        setBethide(false);
        fetchMyBetsData();
        // fetchwalletamount();
        setShowSection(false);

        setShow1(false);
        setBethide(false);
      } else {
        // Error case
        setteamexposercurrent1(0);
        setteamexposercurrent2(0);
        Swal.fire({
          icon: "error",
          title: "Error",
          timer: 2000,
          text: response.data.error_message,
        });
        setBethide(false);
        setShow1(false);
        setBethide(false);
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "API Error: " + error.message,
        timer: 1000,
      });
      setBethide(false);
      console.error("API Error:", error);
    } finally {
      setTimeout(() => {
        setIsButtonDisabled(false);
        setIsButtonVisible(true);
      }, 2000);
    }
  };
  const handlePlaceBet = async (e) => {
    setIsDisabledSubmit(true);
    e.preventDefault();
    setIsButtonDisabled(true);
    setIsButtonVisible(false);
    setErrors({});
   
    if (!bettingvalue) {
      setErrors({ bettingvalue: "" });

      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please enter an amount!",
        timer: 1000,
      });
      setTimeout (() =>{

        setIsDisabledSubmit(false);
      },3000)
      return false;
    }
  
    const token = localStorage.getItem("token");
    const formData = new FormData();
    formData.append("fancy_id", selectedfid);
    formData.append("betSlip_stake", bettingvalue);
    formData.append("betSlip_odds", oddValue);
    // formData.append('stake', '100');
    formData.append("bet_on", selectedbettype);
    formData.append("user_id", userid);
    formData.append("total", stackvaluetotal);
    formData.append("event_id", selectedEid);
    formData.append("team", selectedname);
    formData.append("sport_id", "4");

    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    };

    try {
      const response = await Axios.post(
        `${process.env.REACT_APP_API_URL}place-session-bet`,
        formData,
        { headers }
      );

      console.log("API Response:", response.data);
      setIsDisabledSubmit(false);
      if (response.data.status_code === 1) {
        // Success case
        navigate(
          `/Bettingpage?id=${ptid}&eventid=${event_id}&matchid=${matchid}&name=${name}&reload=${new Date().getTime()}`
        );
        Swal.fire({
          icon: "success",
          title: "Success",
          text: response.data.message,

          showConfirmButton: false,
          timer: 1000,
        });
        setBethide(false);
        fetchMyBetsData();
        fetchwalletamount();
        // $('.expenseAmtt').html(' ');
        // $('.expenseAmtt').html(response.data.exp);
        setShowSection(false);
      } else {
        // Error case
        Swal.fire({
          icon: "error",
          title: "Error",
          timer: 2000,
          text: response.data.error_message,
        });
        setBethide(false);
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "API Error: " + error.message,
        // timer: 1000,
      });
      setBethide(false);
      console.error("API Error:", error);
    } finally {
      setTimeout(() => {
        setIsButtonDisabled(false);
        setIsButtonVisible(true);
      }, 2000);
    }
  };
  const [activebet, setActivebet] = useState({
    1: true,
    2: true,
    3: true,
    4: true,
    5: true,
  });

  const handleclickid = (id) => {
    setActivebet((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };
  function formatSize(size) {
    return size > 1000 ? (size / 1000).toFixed(1) + "k" : size;
  }
  const [isVisibleScore, setIsVisibleScore] = useState(false);

  const toggleVisibilityScore = () => {
    if (isVisibleScore == true) {
      setIsVisibleScore(false);
    } else {
      setIsVisibleScore(true);
    }
  };

  const [isPopupOpenPBet, setIsPopupOpenPBet] = useState(false);

  const handleButtonClickPBet = () => {
    setIsPopupOpenPBet(true);
  };

  const handleClosePopupPBet = () => {
    setIsPopupOpenPBet(false);
  };

  useEffect(() => {
    fetchExposerteampls();
    fetchGenralSetting();
  }, []);
  const fetchExposerteampls = () => {
    const token = localStorage.getItem("token");

    if (!token) {
      console.error("Token is missing");
      return;
    }

    const apiUrl = process.env.REACT_APP_API_URL;
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    fetch(`${process.env.REACT_APP_API_URL}teampls?eventid=${event_id}`, {
      method: "GET",
      headers: headers,
    })
      .then((response) => response.json())
      .then((data) => {
        setteamExposerAll(data.data);
        setteamExposerAllBook(data.dataBook);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const fetchGenralSetting = () => {
    const token = localStorage.getItem("token");

    if (!token) {
      console.error("Token is missing");
      return;
    }

    const apiUrl = process.env.REACT_APP_API_URL;
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    fetch(`${process.env.REACT_APP_API_URL}general-setting`, {
      method: "GET",
      headers: headers,
    })
      .then((response) => response.json())
      .then((data) => {
        setGenralSetting(data.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  return (
    <div className="betting_page">
      <div className="d-flex battingpage justify-content-start gap-2">
        <div className="position-relativel p-0 betting_section">
          <div className="mobile_show_tv">
            <div
              className="watchtv_online my_bet  headingallbet align-items-center d-flex justify-content-between "
              onClick={tvshowhandle}
            >
              <h4 className="mb-0 mt-0 bet-slip-title">Live Match</h4>
              <div className="d-flex gap-2 align-items-center">
                <FaTv />
                <span>Watch Match</span>
              </div>
            </div>
            {tvshow && (
              <>
                <div className="matchtv">
                  <div className="closetv" onClick={toggleVisibility}>
                    {isVisible ? <IoIosCloseCircleOutline /> : ""}
                  </div>
                  <iframe
                    src={`https://dtv.diamondapi.uk/tv-cricket/index.html?eventId=${event_id}`}
                    style={{ width: "100%" }}
                    height={200}
                    controls
                  ></iframe>
                </div>
              </>
            )}
          </div>
          <div className="d-flex justify-content-between round_id align-items-center">
            <h3 className="mb-0 pb-0">{name}</h3>
            <button onClick={toggleVisibilityScore} className="btn-custumtb">
              {isVisibleScore ? <span>Hide</span> : <span>Show</span>} Score
            </button>
            {/* <h3 className="mb-0 pb-0">Round ID: 1452142145</h3> */}
          </div>

          {isVisibleScore && (
            <>
              <div className="matchtv">
                <iframe
                  src={`https://diamondapi.uk/dcasino/graph.php?eventid=${event_id}&sportid=4`}
                  height={200}
                  controls
                  style={{ width: "100%" }}
                ></iframe>
                {/* <div dangerouslySetInnerHTML={{ __html: dataScore }} /> */}
                {/* <div className="closetv" onClick={toggleVisibility}>
                  {isVisibletv ? <IoIosCloseCircleOutline /> : ""}
                </div> */}
                {/* <Player autoPlay className="videoheight"> */}
                {/* <source src={video} /> */}
                {/* <source
                    src={`https://www.satsports.net/score_widget/index.html?id=50722743&aC=${event_id}`}
                  /> */}
                {/* </Player> */}
              </div>
            </>
          )}
          {/* Match_odds bet  */}

          <div className="bettingforall ">
            <div
              className="buttonacrrodian d-flex justify-content-between"
              onClick={() => handleclickid(1)}
            >
              <span>
                <FaAngleDown /> Match Odds
              </span>
            </div>
            <div className="bet-table-body">
              {activebet[1] && (
                <div className="bet_details_all">
                  <div className="d-flex headinglay ">
                    <div className="width_min bettingwidth">
                      Min:{GenralSetting.odd_min} Max:{GenralSetting.odd_max}
                    </div>
                    <div className="back">Back</div>
                    <div className="lay">lay</div>
                    {/* <div className="width_72"></div>
                    <div className="width_72"></div> */}
                  </div>

                  <div className="d-flex headinglay position-relative mobileresponsive">
                    <>
                      {fancyDatateam12 && fancyDatateam12.length > 0 ? (
                        fancyDatateam12.map((item, index) => (
                          <div
                            key={index}
                            className="d-flex position-relative w-100 mobilewrap"
                          >
                            <div className="width_min bettingwidth justify-content-start">
                              <div className="d-flex gap-2 align-items-center">
                                <div> {item.team_name}</div>

                                {teamExposerAll.map((team, index) =>
                                  team.team_id == item.team_id ? (
                                    <div
                                      key={index}
                                      style={{
                                        color:
                                          team.amount < 0
                                            ? "#ff0000"
                                            : "#00ff00",
                                      }}
                                    >
                                      {team.amount < 0
                                        ? `${team.amount}`
                                        : `${team.amount}`}
                                    </div>
                                  ) : (
                                    <React.Fragment
                                      key={index}
                                    ></React.Fragment>
                                  )
                                )}

                                {/* {teamExposerAll[index].team_id ==
                                item.team_id ? (
                                  teamExposerAll[index].amount < 0 ? (
                                    <div
                                      style={{
                                        color:
                                          teamExposerAll[index].amount < 0
                                            ? "#ff0000"
                                            : "#00ff00",
                                      }}
                                    >
                                      {teamExposerAll[index].amount}a
                                    </div>
                                  ) : (
                                    <div
                                      style={{
                                        color:
                                          teamExposerAll[index].amount < 0
                                            ? "#ff0000"
                                            : "#00ff00",
                                      }}
                                    >
                                      {teamExposerAll[2].amount}s
                                    </div>
                                  )
                                ) : (
                                  <React.Fragment></React.Fragment>
                                )} */}

                                {/* {item.exp == 0 ? (
                                  <React.Fragment></React.Fragment>
                                ) : item.exp < 0 ? (
                                  <div
                                    style={{
                                      color:
                                        item.exp < 0 ? "#ff0000" : "#00ff00",
                                    }}
                                  >
                                    {item.exp}
                                  </div>
                                ) : (
                                  <div
                                    style={{
                                      color:
                                        item.exp < 0 ? "#ff0000" : "#00ff00",
                                    }}
                                  >
                                    {item.exp}
                                  </div>
                                )} */}

                                {matchOddsShowCurrentExp ? (
                                  teamexposercurrent1Name == item.team_name ? (
                                    <div style={{ color: currentExpbgColor1 }}>
                                      {" "}
                                      {teamexposercurrent1 == 0 ? (
                                        <></>
                                      ) : (
                                        "(" + teamexposercurrent1 + ")"
                                      )}{" "}
                                    </div>
                                  ) : (
                                    <div style={{ color: currentExpbgColor2 }}>
                                      {teamexposercurrent2 == 0 ? (
                                        <></>
                                      ) : (
                                        "(" + teamexposercurrent2 + ")"
                                      )}
                                    </div>
                                  )
                                ) : (
                                  <></>
                                )}
                              </div>
                              <small>{item.value}</small>
                            </div>

                            <div className="d-flex align-items-center position-relative  justify_content_between">
                              {item.status != "OPEN" ? (
                                <div className="suspended matchods"></div>
                              ) : (
                                <></>
                              )}
                              {item.series &&
                                item.series[0]?.status !== "ACTIVE" && (
                                  <div className="suspended matchods"></div>
                                )}

                              <div className="d-flex align-items-center mobile_width">
                                {item.series &&
                                item.series[0]?.ex?.availableToBack?.length >
                                  0 ? (
                                  item.series[0].ex.availableToBack
                                    .slice()
                                    .reverse()
                                    .map((back, i) => (
                                      <div
                                        key={i}
                                        className={`backbet_design  position-relative faluate${i}`}
                                        onClick={() =>
                                          handleValueClickTeam(
                                            color2,
                                            back.price,
                                            item.team_name,
                                            item.team_id,
                                            item.market_id,
                                            "back",
                                            back.size,
                                            "match_odds"
                                            // back.price,
                                            // item.team_id
                                          )
                                        }
                                      >
                                        <span className="d-block">
                                          {back.price}
                                        </span>
                                        <span>{formatSize(back.size)}</span>
                                      </div>
                                    ))
                                ) : (
                                  <>
                                    <div className="backbet_design position-relative">
                                      <span className="d-block">-</span>
                                      <span>-</span>
                                    </div>
                                    <div className="backbet_design position-relative">
                                      <span className="d-block">-</span>
                                      <span>-</span>
                                    </div>
                                    <div className="backbet_design position-relative">
                                      <span className="d-block">-</span>
                                      <span>-</span>
                                    </div>
                                  </>
                                )}
                                {item.series &&
                                item.series[0]?.ex?.availableToLay?.length >
                                  0 ? (
                                  item.series[0].ex.availableToLay.map(
                                    (lay, i) => (
                                      <div
                                        key={i}
                                        className={`laybet_design position-relative  faluatesec${i}`}
                                        onClick={() =>
                                          handleValueClickTeam(
                                            color1,
                                            lay.price,
                                            item.team_name,
                                            item.team_id,
                                            lay.market_id,
                                            "lay",
                                            lay.size,
                                            "match_odds"
                                          )
                                        }
                                      >
                                        <span className="d-block">
                                          {lay.price}
                                        </span>
                                        <span>{formatSize(lay.size)}</span>
                                      </div>
                                    )
                                  )
                                ) : (
                                  <>
                                    <div className="laybet_design position-relative">
                                      <span className="d-block">-</span>
                                      <span>-</span>
                                    </div>
                                    <div className="laybet_design position-relative">
                                      <span className="d-block">-</span>
                                      <span>-</span>
                                    </div>
                                    <div className="laybet_design position-relative">
                                      <span className="d-block">-</span>
                                      <span>-</span>
                                    </div>
                                  </>
                                )}
                              </div>

                              {/* <div className="d-flex align-items-center mobile_width">
          <div className="position-relative d-flex">
          
          </div>
        </div> */}
                            </div>
                          </div>
                        ))
                      ) : (
                        <div className="d-flex justify-content-center w-100">
                          <img
                            src={loading}
                            width={40}
                            height={40}
                            className="loaderfile"
                            alt="Loading"
                          />
                        </div>
                      )}
                    </>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="bettingforall ">
            <div
              className="buttonacrrodian d-flex justify-content-between"
              onClick={() => handleclickid(2)}
            >
              <span>
                <FaAngleDown /> Bookmaker
              </span>
            </div>
            <div className="bet-table-body">
              {activebet[2] && (
                <div className="bet_details_all">
                  <div className="d-flex headinglay ">
                    <div className="width_min bettingwidth">
                      Min:{GenralSetting.bookmaker_min} Max:
                      {GenralSetting.bookmaker_max}
                    </div>
                    <div className="back">Back</div>
                    <div className="lay">lay</div>
                    {/* <div className="width_72"></div>
                    <div className="width_72"></div> */}
                  </div>
                  <div className="d-flex headinglay position-relative">
                    <>
                      {BookMakerList && BookMakerList.length > 0 ? (
                        BookMakerList.map((item, index) => (
                          <div
                            key={index}
                            className="d-flex position-relative w-100 mobilewrap"
                          >
                            <div className="width_min bettingwidth justify-content-start">
                              <div className="d-flex gap-2 align-items-center">
                                <div> {item.team_name}</div>

                                {teamExposerAllBook.map((team, index) =>
                                  team.team_id == item.team_id ? (
                                    <div
                                      key={index}
                                      style={{
                                        color:
                                          team.amount < 0
                                            ? "#ff0000"
                                            : "#00ff00",
                                      }}
                                    >
                                      {team.amount < 0
                                        ? `${team.amount}`
                                        : `${team.amount}`}
                                    </div>
                                  ) : (
                                    <React.Fragment
                                      key={index}
                                    ></React.Fragment>
                                  )
                                )}
                                {bookMakerShowCurrentExp ? (
                                  teamexposercurrent1Name == item.team_name ? (
                                    <div style={{ color: currentExpbgColor1 }}>
                                      {" "}
                                      {teamexposercurrent1 == 0 ? (
                                        <></>
                                      ) : (
                                        "(" + teamexposercurrent1 + ")"
                                      )}{" "}
                                    </div>
                                  ) : (
                                    <div style={{ color: currentExpbgColor2 }}>
                                      {teamexposercurrent2 == 0 ? (
                                        <></>
                                      ) : (
                                        "(" + teamexposercurrent2 + ")"
                                      )}
                                    </div>
                                  )
                                ) : (
                                  <></>
                                )}
                              </div>
                              <small>{item.value}</small>
                            </div>

                            <div className="d-flex align-items-center position-relative  justify_content_between">
                              {/* {item.status != "OPEN" ? (
                                <div className="suspended matchods"></div>
                              ) : (
                                <></>
                              )} */}
                              {/* {item.series &&
                                item.series[0]?.status !== "ACTIVE" && (
                                  <div className="suspended matchods"></div>
                                )} */}

                              <div className="d-flex align-items-center mobile_width">
                                {item.series && item.series[0]?.odds ? (
                                  <>
                                    <div
                                      className={`${
                                        item.series[0]?.odds.b3s == "True"
                                          ? "tyytya"
                                          : "offbookmakerKey"
                                      } ${
                                        item.series[0]?.odds.b3 > 1
                                          ? "tyytya"
                                          : "offbookmakerKey"
                                      }`}
                                    >
                                      <div
                                        key={index}
                                        className={`position-relative faluate${index} backbet_design`}
                                        onClick={() =>
                                          handleValueClickTeam(
                                            "#72bbef",
                                            item.series[0]?.odds.b3,
                                            item.series[0]?.odds.nat,
                                            item.team_id,
                                            item.market_id,
                                            "back",
                                            item.series[0]?.odds.bs3,
                                            "bookmaker"
                                          )
                                        }
                                      >
                                        <span className="d-block">
                                          {item.series[0]?.odds.b3}
                                        </span>
                                        <span>
                                          {formatSize(item.series[0]?.odds.bs3)}
                                        </span>
                                      </div>
                                    </div>
                                    <div
                                      className={`${
                                        item.series[0]?.odds.b2s == "True"
                                          ? "tyytya"
                                          : "offbookmakerKey"
                                      } ${
                                        item.series[0]?.odds.b2 > 1
                                          ? "tyytya"
                                          : "offbookmakerKey"
                                      }`}
                                    >
                                      <div
                                        key={index}
                                        className={`position-relative faluate${index} backbet_design`}
                                        onClick={() =>
                                          handleValueClickTeam(
                                            "#72bbef",
                                            item.series[0]?.odds.b2,
                                            item.series[0]?.odds.nat,
                                            item.team_id,
                                            item.market_id,
                                            "back",
                                            item.series[0]?.odds.bs2,
                                            "bookmaker"
                                          )
                                        }
                                      >
                                        <span className="d-block">
                                          {item.series[0]?.odds.b2}
                                        </span>
                                        <span>
                                          {formatSize(item.series[0]?.odds.bs2)}
                                        </span>
                                      </div>
                                    </div>
                                    <div
                                      className={`${
                                        item.series[0]?.odds.b1s == "True"
                                          ? "tyytya"
                                          : "offbookmakerKey"
                                      } ${
                                        item.series[0]?.odds.b1 > 1
                                          ? "tyytya"
                                          : "offbookmakerKey"
                                      }`}
                                    >
                                      <div
                                        key={index}
                                        className={`position-relative faluate${index} backbet_design`}
                                        onClick={() =>
                                          handleValueClickTeam(
                                            "#72bbef",
                                            item.series[0]?.odds.b1,
                                            item.series[0]?.odds.nat,
                                            item.team_id,
                                            item.market_id,
                                            "back",
                                            item.series[0]?.odds.bs1,
                                            "bookmaker"
                                          )
                                        }
                                      >
                                        <span className="d-block">
                                          {item.series[0]?.odds.b1}
                                        </span>
                                        <span>
                                          {formatSize(item.series[0]?.odds.bs1)}
                                        </span>
                                      </div>
                                    </div>

                                    {/* lay */}
                                    <div
                                      className={`${
                                        item.series[0]?.odds.l1s == "True"
                                          ? "tyytya"
                                          : "offbookmakerKey"
                                      } ${
                                        item.series[0]?.odds.l1 > 1
                                          ? "tyytya"
                                          : "offbookmakerKey"
                                      }`}
                                    >
                                      <div
                                        key={index}
                                        className={`position-relative faluate${index} 
                                         laybet_design`}
                                        onClick={() =>
                                          handleValueClickTeam(
                                            "#f994ba",
                                            item.series[0]?.odds.l1,
                                            item.series[0]?.odds.nat,
                                            item.team_id,
                                            item.market_id,
                                            "lay",
                                            item.series[0]?.odds.ls1,
                                            "bookmaker"
                                          )
                                        }
                                      >
                                        <span className="d-block">
                                          {item.series[0]?.odds.l1}
                                        </span>
                                        <span>
                                          {formatSize(item.series[0]?.odds.ls1)}
                                        </span>
                                      </div>
                                    </div>
                                    <div
                                      className={`${
                                        item.series[0]?.odds.l2s == "True"
                                          ? "tyytya"
                                          : "offbookmakerKey"
                                      } ${
                                        item.series[0]?.odds.l2 > 1
                                          ? "tyytya"
                                          : "offbookmakerKey"
                                      }`}
                                    >
                                      <div
                                        key={index}
                                        className={`position-relative faluate${index} 
                                         laybet_design`}
                                        onClick={() =>
                                          handleValueClickTeam(
                                            "#f994ba",
                                            item.series[0]?.odds.l2,
                                            item.series[0]?.odds.nat,
                                            item.team_id,
                                            item.market_id,
                                            "lay",
                                            item.series[0]?.odds.ls2,
                                            "bookmaker"
                                          )
                                        }
                                      >
                                        <span className="d-block">
                                          {item.series[0]?.odds.l2}
                                        </span>
                                        <span>
                                          {formatSize(item.series[0]?.odds.ls2)}
                                        </span>
                                      </div>
                                    </div>
                                    <div
                                      className={`${
                                        item.series[0]?.odds.l3s == "True"
                                          ? "tyytya"
                                          : "offbookmakerKey"
                                      } ${
                                        item.series[0]?.odds.l3 > 1
                                          ? "tyytya"
                                          : "offbookmakerKey"
                                      }`}
                                    >
                                      <div
                                        key={index}
                                        className={`position-relative faluate${index} laybet_design`}
                                        onClick={() =>
                                          handleValueClickTeam(
                                            "#f994ba",
                                            item.series[0]?.odds.l3,
                                            item.series[0]?.odds.nat,
                                            item.team_id,
                                            item.market_id,
                                            "lay",
                                            item.series[0]?.odds.ls3,
                                            "bookmaker"
                                          )
                                        }
                                      >
                                        <span className="d-block">
                                          {item.series[0]?.odds.l3}
                                        </span>
                                        <span>
                                          {formatSize(item.series[0]?.odds.ls3)}
                                        </span>
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div className="backbet_design position-relative">
                                      <span className="d-block">-</span>
                                      <span>-</span>
                                    </div>
                                    <div className="backbet_design position-relative">
                                      <span className="d-block">-</span>
                                      <span>-</span>
                                    </div>
                                    <div className="backbet_design position-relative">
                                      <span className="d-block">-</span>
                                      <span>-</span>
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <div className="d-flex justify-content-center w-100">
                          <img
                            src={loading}
                            width={40}
                            height={40}
                            className="loaderfile"
                            alt="Loading"
                          />
                        </div>
                      )}
                    </>

                    {/* <>
                      {onlyData && onlyData.length < 0 ? (
                        onlyData.map((item, index) => (
                          <div className="d-flex position-relative w-100  ">
                            <div className="width_min   bettingwidth justify-content-start">
                              {item.RunnerName}
                              <small>{item.value}</small>
                            </div>

                            {item.GameStatus === "SUSPEND" ||
                            item.GameStatus === "SUSPENDED" ||
                            item.GameStatus === "BALL_RUN" ||
                            item.GameStatus === "Ball Running" ? (
                              <>
                                <div className="suspended">
                                  {item.GameStatus}
                                </div>

                                <div
                                  className="backbet_design"
                                  onClick={() =>
                                    handleValueClick(
                                      color1,
                                      item.LayPrice1,
                                      item.RunnerName,
                                      item.SelectionId,
                                      item.event_id,
                                      "lay",
                                      item.LaySize1,
                                      item.LayPrice1
                                    )
                                  }
                                >
                                  {parseFloat(item.LayPrice1)
                                    .toFixed(2)
                                    .replace(/\.00$/, "")}
                                  <br />
                                  <span>
                                    {parseFloat(item.LaySize1)
                                      .toFixed(2)
                                      .replace(/\.00$/, "")}
                                  </span>
                                </div>

                                <div
                                  className="laybet_design"
                                  onClick={() =>
                                    handleValueClick(
                                      color2,
                                      item.BackPrice1,
                                      item.RunnerName,
                                      item.SelectionId,
                                      item.event_id,
                                      "back",
                                      item.BackSize1,
                                      item.BackPrice1
                                    )
                                  }
                                >
                                  {parseFloat(item.BackPrice1)
                                    .toFixed(2)
                                    .replace(/\.00$/, "")}
                                  <br />
                                  <span>
                                    {parseFloat(item.BackSize1)
                                      .toFixed(2)
                                      .replace(/\.00$/, "")}
                                  </span>
                                </div>
                              </>
                            ) : null}
                            {item.GameStatus === "" ? (
                              <>
                                <div
                                  className="backbet_design"
                                  onClick={() =>
                                    handleValueClick(
                                      color1,
                                      item.LayPrice1,
                                      item.RunnerName,
                                      item.SelectionId,
                                      item.event_id,
                                      "lay",
                                      item.LaySize1,
                                      item.LayPrice1
                                    )
                                  }
                                >
                                  {parseFloat(item.LayPrice1)
                                    .toFixed(2)
                                    .replace(/\.00$/, "")}
                                  <br />
                                  <span>
                                    {parseFloat(item.LaySize1)
                                      .toFixed(2)
                                      .replace(/\.00$/, "")}
                                  </span>
                                </div>

                                <div
                                  className="laybet_design"
                                  onClick={() =>
                                    handleValueClick(
                                      color2,
                                      item.BackPrice1,
                                      item.RunnerName,
                                      item.SelectionId,
                                      item.event_id,
                                      "back",
                                      item.BackSize1,
                                      item.BackPrice1
                                    )
                                  }
                                >
                                  {parseFloat(item.BackPrice1)
                                    .toFixed(2)
                                    .replace(/\.00$/, "")}
                                  <br />
                                  <span>
                                    {parseFloat(item.BackSize1)
                                      .toFixed(2)
                                      .replace(/\.00$/, "")}
                                  </span>
                                </div>
                              </>
                            ) : null}
                          </div>
                        ))
                      ) : (
                        <div className="d-flex justify-content-center w-100">
                          <img
                            src={loading}
                            width={40}
                            height={40}
                            className="loaderfile"
                          />
                        </div>
                      )}
                    </> */}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="bettingforall normal">
            <div
              className="buttonacrrodian d-flex justify-content-between"
              onClick={() => handleclickid(3)}
            >
              <span>
                <FaAngleDown /> Normal
              </span>
            </div>

            <div className="bet-table-body">
              {activebet[3] && (
                <div className="bet_details_all">
                  <div className="container-fluid p-0">
                    <div className="row row5 d-none-mobile">
                      <div className="col-12 col-md-6 mobilenonere">
                        <div className="fancy-tripple">
                          <div className="bet-table-row">
                            <div className="nation-name" />
                            <div className="lay bl-title lay-title">No</div>
                            <div className="back bl-title back-title">Yes</div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-md-6">
                        <div className="fancy-tripple">
                          <div className="bet-table-row">
                            <div className="nation-name" />
                            <div className="lay bl-title lay-title">No</div>
                            <div className="back bl-title back-title">Yes</div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row row5">
                      {fetchdatamatchone && fetchdatamatchone.length > 0 ? (
                        fetchdatamatchone.map((item, index) => {
                          console.log(item); // Debugging
                          return (
                            <div className="col-12 col-md-6  position-relative">
                              <div className="fancy-tripple" key={index}>
                                <div className="bet-table-mobile-row">
                                  <div className="bet-table-row">
                                    <div className="nation-name d-none-mobile">
                                      <p className="fw-bold">
                                        {item.RunnerName}
                                      </p>
                                      <p>{item.value}</p>
                                    </div>

                                    {item.GameStatus === "SUSPEND" ||
                                    item.GameStatus === "SUSPENDED" ||
                                    item.GameStatus === "BALL_RUN" ||
                                    item.GameStatus === "Ball Running" ? (
                                      <>
                                        <div className="suspended normalsuspand">
                                          {item.GameStatus}
                                        </div>

                                        <div
                                          className="backbet_design"
                                          onClick={() =>
                                            handleValueClick(
                                              color1,
                                              item.LayPrice1,
                                              item.RunnerName,
                                              item.SelectionId,
                                              item.event_id,
                                              "lay",
                                              item.LaySize1,
                                              item.LayPrice1
                                            )
                                          }
                                        >
                                          <span class="d-block odds">
                                            {" "}
                                            {parseFloat(item.LayPrice1)
                                              .toFixed(2)
                                              .replace(/\.00$/, "")}
                                          </span>

                                          <span>
                                            {parseFloat(item.LaySize1)
                                              .toFixed(2)
                                              .replace(/\.00$/, "")}
                                          </span>
                                        </div>

                                        <div
                                          className="laybet_design"
                                          onClick={() =>
                                            handleValueClick(
                                              color2,
                                              item.BackPrice1,
                                              item.RunnerName,
                                              item.SelectionId,
                                              item.event_id,
                                              "back",
                                              item.BackSize1,
                                              item.BackPrice1
                                            )
                                          }
                                        >
                                          <span class="d-block odds">
                                            {" "}
                                            {parseFloat(item.BackPrice1)
                                              .toFixed(2)
                                              .replace(/\.00$/, "")}
                                          </span>

                                          <span>
                                            {parseFloat(item.BackSize1)
                                              .toFixed(2)
                                              .replace(/\.00$/, "")}
                                          </span>
                                        </div>
                                      </>
                                    ) : null}
                                    <div data-title="" className="">
                                      {item.GameStatus === "" ? (
                                        <>
                                          <div
                                            className="bl-box lay"
                                            onClick={() =>
                                              handleValueClick(
                                                color1,
                                                item.LayPrice1,
                                                item.RunnerName,
                                                item.SelectionId,
                                                item.event_id,
                                                "lay",
                                                item.LaySize1,
                                                item.LayPrice1
                                              )
                                            }
                                          >
                                            <span class="d-block odds">
                                              {" "}
                                              {parseFloat(item.LayPrice1)
                                                .toFixed(2)
                                                .replace(/\.00$/, "")}
                                            </span>

                                            <span className="d-block">
                                              {parseFloat(item.LaySize1)
                                                .toFixed(2)
                                                .replace(/\.00$/, "")}
                                            </span>
                                          </div>

                                          <div
                                            className="bl-box back"
                                            onClick={() =>
                                              handleValueClick(
                                                color2,
                                                item.BackPrice1,
                                                item.RunnerName,
                                                item.SelectionId,
                                                item.event_id,
                                                "back",
                                                item.BackSize1,
                                                item.BackPrice1
                                              )
                                            }
                                          >
                                            <span className="d-block odds">
                                              {" "}
                                              {parseFloat(item.BackPrice1)
                                                .toFixed(2)
                                                .replace(/\.00$/, "")}
                                            </span>

                                            <span className="d-block">
                                              {parseFloat(item.BackSize1)
                                                .toFixed(2)
                                                .replace(/\.00$/, "")}
                                            </span>
                                          </div>
                                        </>
                                      ) : null}
                                    </div>
                                    <div className="fancy-min-max">
                                      Min:<span>100</span>Max:<span>50K</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <div className="d-flex justify-content-center w-100">
                          <img
                            src={loading}
                            width={40}
                            height={40}
                            className="loaderfile"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="bettingforall ">
            <div
              className="buttonacrrodian d-flex justify-content-between"
              onClick={() => handleclickid(4)}
            >
              <span>
                <FaAngleDown /> over by over
              </span>
            </div>
            <div className="bet-table-body">
              {activebet[4] && (
                <div className="bet_details_all">
                  <div className="d-flex headinglay ">
                    <div className="width_min bettingwidth">
                      Min:100 Max:1000
                    </div>
                    <div className="back">Back</div>
                    <div className="lay">lay</div>
                    {/* <div className="width_72"></div>
                    <div className="width_72"></div> */}
                  </div>
                  <div className="d-flex headinglay position-relative">
                    <>
                      {onlyData && onlyData.length > 0 ? (
                        onlyData.map((item, index) => (
                          <div className="d-flex position-relative w-100  ">
                            <div className="width_min   bettingwidth justify-content-start">
                              {item.RunnerName}
                              <small>{item.value}</small>
                            </div>

                            {item.GameStatus === "SUSPEND" ||
                            item.GameStatus === "SUSPENDED" ||
                            item.GameStatus === "BALL_RUN" ||
                            item.GameStatus === "Ball Running" ? (
                              <>
                                <div className="suspended">
                                  {item.GameStatus}
                                </div>

                                <div
                                  className="backbet_design"
                                  onClick={() =>
                                    handleValueClick(
                                      color1,
                                      item.LayPrice1,
                                      item.RunnerName,
                                      item.SelectionId,
                                      item.event_id,
                                      "lay",
                                      item.LaySize1,
                                      item.LayPrice1
                                    )
                                  }
                                >
                                  {parseFloat(item.LayPrice1)
                                    .toFixed(2)
                                    .replace(/\.00$/, "")}
                                  <br />
                                  <span>
                                    {parseFloat(item.LaySize1)
                                      .toFixed(2)
                                      .replace(/\.00$/, "")}
                                  </span>
                                </div>

                                <div
                                  className="laybet_design"
                                  onClick={() =>
                                    handleValueClick(
                                      color2,
                                      item.BackPrice1,
                                      item.RunnerName,
                                      item.SelectionId,
                                      item.event_id,
                                      "back",
                                      item.BackSize1,
                                      item.BackPrice1
                                    )
                                  }
                                >
                                  {parseFloat(item.BackPrice1)
                                    .toFixed(2)
                                    .replace(/\.00$/, "")}
                                  <br />
                                  <span>
                                    {parseFloat(item.BackSize1)
                                      .toFixed(2)
                                      .replace(/\.00$/, "")}
                                  </span>
                                </div>
                              </>
                            ) : null}
                            {item.GameStatus === "" ? (
                              <>
                                <div
                                  className="backbet_design"
                                  onClick={() =>
                                    handleValueClick(
                                      color1,
                                      item.LayPrice1,
                                      item.RunnerName,
                                      item.SelectionId,
                                      item.event_id,
                                      "lay",
                                      item.LaySize1,
                                      item.LayPrice1
                                    )
                                  }
                                >
                                  {parseFloat(item.LayPrice1)
                                    .toFixed(2)
                                    .replace(/\.00$/, "")}
                                  <br />
                                  <span>
                                    {parseFloat(item.LaySize1)
                                      .toFixed(2)
                                      .replace(/\.00$/, "")}
                                  </span>
                                </div>

                                <div
                                  className="laybet_design"
                                  onClick={() =>
                                    handleValueClick(
                                      color2,
                                      item.BackPrice1,
                                      item.RunnerName,
                                      item.SelectionId,
                                      item.event_id,
                                      "back",
                                      item.BackSize1,
                                      item.BackPrice1
                                    )
                                  }
                                >
                                  {parseFloat(item.BackPrice1)
                                    .toFixed(2)
                                    .replace(/\.00$/, "")}
                                  <br />
                                  <span>
                                    {parseFloat(item.BackSize1)
                                      .toFixed(2)
                                      .replace(/\.00$/, "")}
                                  </span>
                                </div>
                                {/* <div className="fancy-min-max">
                                  Min:<span>100</span>Max:<span>50K</span>
                                </div> */}
                              </>
                            ) : null}
                          </div>
                        ))
                      ) : (
                        <div className="d-flex justify-content-center w-100">
                          <img
                            src={loading}
                            width={40}
                            height={40}
                            className="loaderfile"
                          />
                        </div>
                      )}
                    </>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="bettingforall ">
            <div
              className="buttonacrrodian d-flex justify-content-between"
              onClick={() => handleclickid(5)}
            >
              <span>
                <FaAngleDown />
                Ball By Ball Session Market
              </span>
            </div>
            <div className="bet-table-body">
              {activebet[5] && (
                <div className="bet_details_all">
                  <div className="d-flex headinglay ">
                    <div className="width_min bettingwidth">
                      Min:100 Max:1000
                    </div>
                    <div className="back">Back</div>
                    <div className="lay">lay</div>
                    {/* <div className="width_72"></div>
                    <div className="width_72"></div> */}
                  </div>
                  <div className="d-flex headinglay position-relative">
                    <>
                      {onlyData && onlyData.length > 0 ? (
                        onlyData.map((item, index) => (
                          <div className="d-flex position-relative w-100  ">
                            <div className="width_min   bettingwidth justify-content-start">
                              {item.RunnerName}
                              <small>{item.value}</small>
                            </div>

                            {item.GameStatus === "SUSPEND" ||
                            item.GameStatus === "SUSPENDED" ||
                            item.GameStatus === "BALL_RUN" ||
                            item.GameStatus === "Ball Running" ? (
                              <>
                                <div className="suspended">
                                  {item.GameStatus}
                                </div>

                                <div
                                  className="backbet_design"
                                  onClick={() =>
                                    handleValueClick(
                                      color1,
                                      item.LayPrice1,
                                      item.RunnerName,
                                      item.SelectionId,
                                      item.event_id,
                                      "lay",
                                      item.LaySize1,
                                      item.LayPrice1
                                    )
                                  }
                                >
                                  {parseFloat(item.LayPrice1)
                                    .toFixed(2)
                                    .replace(/\.00$/, "")}
                                  <br />
                                  <span>
                                    {parseFloat(item.LaySize1)
                                      .toFixed(2)
                                      .replace(/\.00$/, "")}
                                  </span>
                                </div>

                                <div
                                  className="laybet_design"
                                  onClick={() =>
                                    handleValueClick(
                                      color2,
                                      item.BackPrice1,
                                      item.RunnerName,
                                      item.SelectionId,
                                      item.event_id,
                                      "back",
                                      item.BackSize1,
                                      item.BackPrice1
                                    )
                                  }
                                >
                                  {parseFloat(item.BackPrice1)
                                    .toFixed(2)
                                    .replace(/\.00$/, "")}
                                  <br />
                                  <span>
                                    {parseFloat(item.BackSize1)
                                      .toFixed(2)
                                      .replace(/\.00$/, "")}
                                  </span>
                                </div>
                              </>
                            ) : null}
                            {item.GameStatus === "" ? (
                              <>
                                <div
                                  className="backbet_design"
                                  onClick={() =>
                                    handleValueClick(
                                      color1,
                                      item.LayPrice1,
                                      item.RunnerName,
                                      item.SelectionId,
                                      item.event_id,
                                      "lay",
                                      item.LaySize1,
                                      item.LayPrice1
                                    )
                                  }
                                >
                                  {parseFloat(item.LayPrice1)
                                    .toFixed(2)
                                    .replace(/\.00$/, "")}
                                  <br />
                                  <span>
                                    {parseFloat(item.LaySize1)
                                      .toFixed(2)
                                      .replace(/\.00$/, "")}
                                  </span>
                                </div>

                                <div
                                  className="laybet_design"
                                  onClick={() =>
                                    handleValueClick(
                                      color2,
                                      item.BackPrice1,
                                      item.RunnerName,
                                      item.SelectionId,
                                      item.event_id,
                                      "back",
                                      item.BackSize1,
                                      item.BackPrice1
                                    )
                                  }
                                >
                                  {parseFloat(item.BackPrice1)
                                    .toFixed(2)
                                    .replace(/\.00$/, "")}
                                  <br />
                                  <span>
                                    {parseFloat(item.BackSize1)
                                      .toFixed(2)
                                      .replace(/\.00$/, "")}
                                  </span>
                                </div>
                                {/* <div className="fancy-min-max">
                                  Min:<span>100</span>Max:<span>50K</span>
                                </div> */}
                              </>
                            ) : null}
                          </div>
                        ))
                      ) : (
                        <div className="d-flex justify-content-center w-100">
                          <img
                            src={loading}
                            width={40}
                            height={40}
                            className="loaderfile"
                          />
                        </div>
                      )}
                    </>
                  </div>
                </div>
              )}
            </div>
          </div>

          <Footer />
        </div>

        <div className="section_right">
          <div className="mobile_d-none-online">
            <div
              className="watchtv_online my_bet  headingallbet align-items-center d-flex justify-content-between "
              onClick={tvshowhandle}
            >
              <h4 className="mb-0 mt-0 bet-slip-title">Live Match</h4>
              <div className="d-flex gap-2 align-items-center">
                <FaTv />
                <span>Watch Match</span>
              </div>
            </div>
            {tvshow && (
              <>
                <div className="matchtv">
                  <div className="closetv" onClick={toggleVisibility}>
                    {isVisible ? <IoIosCloseCircleOutline /> : ""}
                  </div>
                  {/* <Player autoPlay className="videoheight">
                  <source
                    src={`https://dtv.diamondapi.uk/tv-cricket/index.html?eventId=${event_id}`}
                  />
                </Player> */}
                  <iframe
                    src={`https://dtv.diamondapi.uk/tv-cricket/index.html?eventId=${event_id}`}
                    style={{ width: "100%" }}
                    height={200}
                    controls
                  ></iframe>
                </div>
              </>
            )}
          </div>
          <div className="p-0 placebet_section  d-sm-none-mobile">
            <div className="placeingbet">
              {bethide === true && (
                <div className="Betarea">
                  <div className="headingallbet d-flex justify-content-between">
                    <h4 className="bet-slip-title">Bet Place</h4>
                    <div className="" onClick={toggleVisibility}>
                      {isVisible ? (
                        ""
                      ) : (
                        <p className="playtv">Watch Live Match</p>
                      )}
                    </div>
                  </div>
                  {/* team bet place  */}
                  {stackValueteam && (
                    <div
                      className="matchname"
                      style={{ backgroundColor: bgColor }}
                    >
                      <div className="matchnamelist">
                        <p className="mb-0">
                          {name}
                          <span> {selectednameteam}</span>
                        </p>
                      </div>
                      {/* <div className="d-flex justify-content-between borderbottomscore">
                    <p className="pb-0 mb-0">Ind</p>
                    <p className="pb-0 mb-0">1.74</p>
                  </div> */}
                      <div className="betting_input d-flex justify-content-between">
                        <Form.Group>
                          <Form.Control
                            className="inputbetting"
                            type="number"
                            value={bettingvalueteam}
                            onChange={inputchangeteam}
                          />
                        </Form.Group>
                        <Form.Control
                          className="inputbetting"
                          type="number"
                          value={stackValueteam}
                          // onChange={inputchange}
                        />
                        {/* <div className="Profit">
                      <p className="mb-0">Profit</p>
                      <span>2500</span>
                    </div> */}
                      </div>
                      <div className="d-flex justify-content-start flex-wrap">
                        {buttonValues &&
                          buttonValues.map((value, index) => (
                            <button
                              key={index}
                              className="btn amountbtn"
                              onClick={() => valuebettingteam(value)}
                            >
                              {value}
                            </button>
                          ))}
                      </div>

                      <div className="placebet_design">
                        <button
                          className="btn placebet w-100"
                          onClick={handlePlaceBetteam}
                          disabled={isDisabledSubmit}
                        >
                          Place Bet
                        </button>
                      </div>
                    </div>
                  )}
                  {selectedname && (
                    <div
                      className="matchname"
                      style={{ backgroundColor: bgColor }}
                    >
                      <div className="matchnamelist">
                        <p className="mb-0">
                          {name}
                          <span> {selectedname}</span>
                        </p>
                      </div>
                      {/* <div className="d-flex justify-content-between borderbottomscore">
                    <p className="pb-0 mb-0">Ind</p>
                    <p className="pb-0 mb-0">1.74</p>
                  </div> */}
                      <div className="betting_input d-flex justify-content-between">
                        <Form.Group>
                          <Form.Control
                            className="inputbetting"
                            type="number"
                            value={bettingvalue}
                            onChange={inputchange}
                          />
                        </Form.Group>
                        <Form.Control
                          className="inputbetting"
                          type="number"
                          value={stackValue}
                          // onChange={inputchange}
                        />
                        {/* <div className="Profit">
                      <p className="mb-0">Profit</p>
                      <span>2500</span>
                    </div> */}
                      </div>
                      <div className="d-flex justify-content-start flex-wrap">
                        {buttonValues &&
                          buttonValues.map((value, index) => (
                            <button
                              key={index}
                              className="btn amountbtn"
                              onClick={() => valuebetting(value)}
                            >
                              {value}
                            </button>
                          ))}
                      </div>

                      <div className="placebet_design">
                        <button
                          className="btn placebet w-100"
                          onClick={handlePlaceBet}
                          disabled={isDisabledSubmit}
                        >
                          Place Bet
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              )}
              <div className="Mybets">
                <h4 className="bet-slip-title">My Bet</h4>
                <div
                  className="my_bet d-flex justify-content-between align-items-center"
                  onClick={togglebet}
                >
                  <span>Normal</span>
                  <FaAngleDown />
                </div>
                <div>
                  {currentItems &&
                    currentItems.map((bet, index) => {
                      return (
                        <div
                          key={index}
                          className="card mb-0.5"
                          style={{
                            backgroundColor:
                              bet.bet_on === "back" ? "#0a0e0e7a" : "#0a0e0e7a",
                          }}
                        >
                          <div className="d-flex justify-content-between align-items-start p-1 gap-1 border-bottom">
                            <div className="text-light">
                              {indexOfFirstItem + index + 1}
                            </div>
                            <div
                              className="betplacecolor lay"
                              style={{
                                backgroundColor:
                                  bet.bet_on === "back" ? "#72bbef" : "#f994ba",
                              }}
                            ></div>
                            <div className="betname">
                              {bet.team}/{bet.stake}
                            </div>
                            <div className="runinbet">{bet.odd}</div>
                            <div className="amountbet">{bet.total}</div>
                          </div>
                        </div>
                      );
                    })}
                </div>
                <div className="pagination">
                  {Array.from({ length: totalPages }, (_, i) => (
                    <button
                      key={i}
                      onClick={() => handlePageChange(i + 1)}
                      className={`text-dark currentPage === i + 1 ? 'active' : ''`}
                    >
                      {i + 1}
                    </button>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <Modal show={show1} onHide={handleClose1} animation={false}>
            <Modal.Header
              className="p-2"
              closeButton
              style={{ background: "#04533a" }}
            >
              <Modal.Title className="text-light">Bet Place </Modal.Title>
            </Modal.Header>

            <Modal.Body
              className="justify-content-start p-0"
              style={{ background: "#444", color: "white" }}
            >
              <div className="mobilepopup">
                <div className="p-0 placebet_section mobile_betting">
                  <div className="placeingbet">
                    {bethide === true && (
                      <div className="Betarea">
                        <div className="headingallbet d-flex justify-content-between">
                          <div className="" onClick={toggleVisibility}>
                            {isVisible ? (
                              ""
                            ) : (
                              <p className="playtv">Watch Live Match</p>
                            )}
                          </div>
                        </div>
                        {stackValueteam && (
                          <div
                            className="matchname"
                            style={{ backgroundColor: bgColor }}
                          >
                            <div className="matchnamelist">
                              <p className="mb-0">
                                {name}
                                <span> {selectednameteam}</span>
                              </p>
                            </div>

                            <div className="betting_input d-flex justify-content-between">
                              <Form.Group>
                                <Form.Control
                                  className="inputbetting"
                                  type="number"
                                  value={bettingvalueteam}
                                  onChange={inputchangeteam}
                                />
                              </Form.Group>
                              <Form.Control
                                className="inputbetting"
                                type="number"
                                value={stackValueteam}
                              />
                            </div>
                            <div className="d-flex justify-content-start flex-wrap">
                              {buttonValues &&
                                buttonValues.map((value, index) => (
                                  <button
                                    key={index}
                                    className="btn amountbtn"
                                    onClick={() => valuebettingteam(value)}
                                  >
                                    {value}
                                  </button>
                                ))}
                            </div>

                            <div className="placebet_design">
                              <button
                                className="btn placebet w-100"
                                onClick={handlePlaceBetteam}
                                disabled={isDisabledSubmit}
                              >
                                Place Bet
                              </button>
                            </div>
                          </div>
                        )}
                        {stackValue && (
                          <div
                            className="matchname"
                            style={{ backgroundColor: bgColor }}
                          >
                            <div className="matchnamelist">
                              <p className="mb-0">
                                {name}
                                <span> {selectedname}</span>
                              </p>
                            </div>

                            <div className="betting_input d-flex justify-content-between">
                              <Form.Group>
                                <Form.Control
                                  className="inputbetting"
                                  type="number"
                                  value={bettingvalue}
                                  onChange={inputchange}
                                />
                              </Form.Group>
                              <Form.Control
                                className="inputbetting"
                                type="number"
                                value={stackValue}
                              />
                            </div>
                            <div className="d-flex justify-content-start flex-wrap">
                              {buttonValues &&
                                buttonValues.map((value, index) => (
                                  <button
                                    key={index}
                                    className="btn amountbtn"
                                    onClick={() => valuebetting(value)}
                                  >
                                    {value}
                                  </button>
                                ))}
                            </div>

                            <div className="placebet_design">
                              <button
                                className="btn placebet w-100"
                                onClick={handlePlaceBet}
                                disabled={isDisabledSubmit}
                              >
                                Place Bet
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                    <div className="Mybets">
                      <h4 className="bet-slip-title">My Bet</h4>
                      <div
                        className="my_bet d-flex  justify-content-between align-items-center"
                        onClick={togglebet}
                      >
                        <span>Normal</span>
                        <FaAngleDown />
                      </div>
                      <div>
                        {currentItems &&
                          currentItems.map((bet, index) => {
                            // const bgColor = bet.color;

                            return (
                              <div
                                key={index}
                                className="d-flex justify-content-between align-items-start p-1 gap-1 border-bottom-1"
                                style={{
                                  backgroundColor:
                                    bet.bet_on === "back"
                                      ? "#0a0e0e7a"
                                      : "#0a0e0e7a",
                                }}
                              >
                                <div className="text-light">
                                  {indexOfFirstItem + index + 1}
                                </div>
                                <div
                                  className="betplacecolor lay"
                                  style={{
                                    backgroundColor:
                                      bet.bet_on === "back"
                                        ? "#72bbef"
                                        : "#f994ba",
                                  }}
                                ></div>

                                <div className="betname">
                                  {bet.team}/{bet.stake}
                                </div>
                                <div className="runinbet">{bet.odd}</div>
                                <div className="amountbet">{bet.total}</div>
                              </div>
                            );
                          })}
                      </div>
                      <div className="pagination">
                        {Array.from({ length: totalPages }, (_, i) => (
                          <button
                            key={i}
                            onClick={() => handlePageChange(i + 1)}
                            className={`text-dark currentPage === i + 1 ? 'active' : ''`}
                          >
                            {i + 1}
                          </button>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
      {myBets.length > 0 ? (
        <div
          className="mobile_button_count main-button"
          onClick={handleButtonClickPBet}
        >
          <p>
            <span className="d-block mybet_new">My Bet</span>
            <span className="new_font_size">{myBets.length}</span>
          </p>
        </div>
      ) : (
        <></>
      )}
      {isPopupOpenPBet && (
        <div>
          <div className="popup new_poup">
            <div className="popup-content-sec">
              <div className="card bg-transparent">
                <div className="card-header bg_green_custum padding_10">
                  <div className="d-flex justify-content-between align-items-center">
                    <h6 className="card-title text-white mb-0">My Bet</h6>
                    <span onClick={handleClosePopupPBet}>
                      <IoClose className="text-white" />
                    </span>
                  </div>
                </div>
                <div className="card-body placebet_section  bg-color-sec p-1 w-100">
                  <div className="Mybets overlflowscrollpopupbettting">
                    <div
                      className="my_bet d-flex justify-content-between align-items-center"
                      onClick={togglebet}
                    >
                      <span>Normal</span>
                    </div>
                    <div>
                      {currentItems &&
                        currentItems.map((bet, index) => {
                          return (
                            <div
                              key={index}
                              className="card mb-0.5  border-radius "
                              style={{
                                backgroundColor:
                                  bet.bet_on === "back"
                                    ? "#0a0e0e7a"
                                    : "#0a0e0e7a",
                              }}
                            >
                              <div className="d-flex justify-content-between align-items-start p-1 gap-1 border-bottom">
                                <div className="text-light">
                                  {indexOfFirstItem + index + 1}
                                </div>
                                <div
                                  className="betplacecolor lay height_all_new"
                                  style={{
                                    backgroundColor:
                                      bet.bet_on === "back"
                                        ? "#72bbef"
                                        : "#f994ba",
                                  }}
                                ></div>
                                <div className="betname text-white">
                                  {bet.team}/{bet.stake}
                                </div>
                                <div className="runinbet text-white">
                                  {bet.odd}
                                </div>
                                <div className="amountbet text-white">
                                  {bet.total}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                    <div className="pagination">
                      {Array.from({ length: totalPages }, (_, i) => (
                        <button
                          key={i}
                          onClick={() => handlePageChange(i + 1)}
                          className={`text-dark currentPage === i + 1 ? 'active' : ''`}
                        >
                          {i + 1}
                        </button>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
