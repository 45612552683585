import React, { useState, useEffect } from 'react';
import './Sidebar.scss';
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { MdSportsCricket, MdOutlineSportsCricket } from 'react-icons/md';
import { FaHome } from 'react-icons/fa';
import Logo from '../assets/images/logosec.png';
import Cricketimage from '../assets/images/cricketicon.png';
import { ImVolumeHigh } from "react-icons/im";
import { GiHamburgerMenu } from "react-icons/gi";
import { IoCloseSharp } from "react-icons/io5";
export default function SidebarLeft() {
  const [cricketData, setCricketData] = useState([]);
  const [tennisData, setTennisData] = useState([]);
  const [footballData, setFootballData] = useState([]);
  const [games, setGames] = useState([]);
  const navigate = useNavigate();
  const [openDropdown, setOpenDropdown] = useState(null);

  const toggleDropdown = (index) => {
    setOpenDropdown(openDropdown === index ? null : index);
  };

  useEffect(() => {
    const token = localStorage.getItem('token');

    const headers = {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    };

    fetch('https://leobook.in/Admin/api/v1/games-types', {
      method: 'GET',
      headers: headers,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        setGames(data.data.games);
      })
      .catch((error) => console.error(error));
  }, []);

  const fetchSportData = async (sportId, setData) => {
    const token = localStorage.getItem('token');
    try {
      const response = await fetch(
        `https://leobook.in/Admin/api/v1/match-list?sport_id=${sportId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      setData(data.data.matches);
    } catch (error) {
      console.error('Error fetching sport data:', error);
    }
  };

  useEffect(() => {
    fetchSportData(4, setCricketData);
    fetchSportData(2, setTennisData);
    fetchSportData(1, setFootballData);
  }, []);
  const handleSeriesClick = (seriesId,sportId) => {
    // Store seriesId in local storage
    localStorage.setItem('selectedSeriesId', seriesId);

    navigate(`/index?sportId=${sportId}&seriesId=${seriesId}`);

  };
  const handleSeriesClick1 = () => {
    navigate('/Home')
    localStorage.setItem('home', 1);
    localStorage.removeItem("selectedSeriesId")
  };
  const renderSportItems = (data, sportId) => {
    return data.map((element) => (
      <li key={element.id}>
        {/* <Link
          to={{
            pathname: '/index',
            search: `?sportId=${sportId}&seriesId=${element.series_id}`,
          }}
          onClick={() => handleSeriesClick(element.series_id)}
          className="d-flex gap-1"
        > */}
        <Link to={`/index?sportId=${sportId}&seriesId=${element.series_id}`} className="d-flex gap-1"  onClick={() => handleSeriesClick(element.series_id,sportId)}>
          <img src={Cricketimage} width={20} height={20} />
          <span className='text-light mx-1 elementname'>{element.name}</span>
        </Link>
      </li>
    ));
  };
  const [isSidebarVisible, setIsSidebarVisible] = useState(true);

  const toggleSidebar = () => {
    setIsSidebarVisible(!isSidebarVisible);
  };
  useEffect(() => {
    // Add or remove class on body when sidebar visibility changes
    if (isSidebarVisible) {
      document.body.classList.add('sidebar-open');
    } else {
      document.body.classList.remove('sidebar-open');
    }

    // Cleanup on component unmount
    return () => {
      document.body.classList.remove('sidebar-open');
    };
  }, [isSidebarVisible]);
 
  return (
    <div className='position-relative'>
     
    <div className={`sidebar mobilesidebar ${isSidebarVisible ? 'open' : 'closed'}`} id="sidebar">
      <button  className="toggle-button">
        {isSidebarVisible ? <GiHamburgerMenu/> : <IoCloseSharp/>}
      </button>
      <Link to="/Home" onClick={handleSeriesClick1} >
        <div className="logo_web">
          <img src={Logo} alt="logo" />
        </div>
      </Link>
      <div>
        <h3 className="heading_game">Racing Sport</h3>
        <div className="menusidebar">
          <ul className="menu">
            <li>
              <Link to="/Home" onClick={handleSeriesClick1} className='nav-link text-white d-flex gap-1 align-items-center'>
                <FaHome /><span>Home</span>
              </Link>

            </li>
            <li>
              <Link to="/Brodcast" className='nav-link text-white d-flex gap-1 align-items-center'>
                <ImVolumeHigh /><span>Brodcast</span>
              </Link>
            </li>
            {games.map((item, index) => (
              <li key={index}>
                <div
                  onClick={() => toggleDropdown(index)}
                  className={`menubar_series ${openDropdown === index ? 'active' : ''}`}
                >
                  <span>
                    <MdSportsCricket /> {item.name}
                  </span>
                </div>
                {openDropdown === index && (
                  <ul className="submenu">
                    {item.id === 4 ? renderSportItems(cricketData, item.id)
                      : item.id === 2 ? renderSportItems(tennisData, item.id)
                        : renderSportItems(footballData, item.id)}
                  </ul>
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>
      </div>
    
      </div>
  );
}
