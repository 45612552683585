import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { Container, Modal } from "react-bootstrap";
import { FaChevronDown } from "react-icons/fa";
import Swal from "sweetalert2";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
export default function Header() {
  const [walletAmount, setWalletAmount] = useState(null);
  const [expamount, setexpamount] = useState(null);

  useEffect(() => {
    fetchwalletamount();
  }, []);
  const fetchwalletamount = async () => {
  
    const token = localStorage.getItem("token");
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}user/wallet-amount`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      
      const data = await response.json();
      
      setWalletAmount(data.amount);
      setexpamount(data.exp);
      
      
      console.warn("response",data)
    } catch (error) {

      console.error("Error fetching wallet amount:", error);
    }
  };

  return (
    <>
      <div className="header_main">
        <div className="d-flex align-items-center gap-4">
          <div className="mobile_flex_wallet">
            <p className="m-0 text-white">
              PTS : <strong>{walletAmount}</strong>
            </p>
            <p className="m-0 text-white">
              EXP : <strong>{expamount && expamount.replace(/\.\d+$/, "").replace(/,/g, '')}</strong>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
