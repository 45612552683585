import React from 'react';

import Footer from '../Page/Footer'

const Aboutus = () => {
  return (
    <div>

<div className="terms-container">
<h1 className="terms-heading">
        About Us - Leobook Cricket Betting
      </h1>
      <p className="terms-paragraph">
        Welcome to Leobook, your premier destination for cricket betting! Our mission is to provide cricket enthusiasts with a top-notch betting experience that is engaging, transparent, and reliable. We are passionate about cricket and committed to delivering the best betting opportunities to our users.
      </p>
      <section className="terms-section">
        <h2>Our Mission</h2>
        <p>
          At Leobook, our mission is to revolutionize the cricket betting landscape by offering a platform that combines innovative technology with a deep understanding of cricket. We aim to create an enjoyable and secure betting environment where users can confidently place their bets and follow their favorite teams and players.
        </p>
      </section>
      <section className="terms-section">
        <h2>What Sets Us Apart</h2>
        <p>
          Leobook stands out for its dedication to user experience and commitment to excellence. Here’s what makes us different:
          <ul>
            <li><strong>Expertise in Cricket:</strong> We have a team of cricket aficionados who ensure that our betting options are comprehensive and tailored to cricket fans.</li>
            <li><strong>Advanced Technology:</strong> Our platform uses cutting-edge technology to offer real-time updates, seamless transactions, and a user-friendly interface.</li>
            <li><strong>Responsible Betting:</strong> We promote responsible gambling and provide tools and resources to help users manage their betting activities effectively.</li>
            <li><strong>Customer Support:</strong> Our dedicated customer support team is available around the clock to assist with any inquiries or issues you may have.</li>
          </ul>
        </p>
      </section>
      <section className="terms-section">
        <h2>Our Values</h2>
        <p>
          At Leobook, we believe in:
          <ul>
            <li><strong>Integrity:</strong> We operate with the highest standards of honesty and transparency in all our dealings.</li>
            <li><strong>Innovation:</strong> We continuously seek to improve our services and adopt the latest technologies to enhance your betting experience.</li>
            <li><strong>Customer-Centric Approach:</strong> Our users are at the heart of everything we do. We strive to understand and meet your needs and preferences.</li>
            <li><strong>Fair Play:</strong> We are committed to ensuring that our platform is fair and equitable for all users, promoting a healthy and competitive environment.</li>
          </ul>
        </p>
      </section>
      <section className="terms-section">
        <h2>Our Team</h2>
        <p>
          Leobook is powered by a diverse team of professionals with extensive experience in cricket, betting, and technology. Our team is dedicated to creating an exceptional platform and delivering outstanding service to our users.
        </p>
      </section>
      <section className="terms-section">
        <h2>Get in Touch</h2>
        <p>
          We value your feedback and are here to help with any questions or suggestions you may have. Feel free to reach out to us at <a href="#">support@leobook.com</a> or connect with us through our social media channels.
        </p>
      </section>
    </div>
    <Footer/>
    </div>
  );
};

export default Aboutus;
