import React, { useState, useEffect } from "react";
import Form from 'react-bootstrap/Form';
import { useNavigate } from "react-router-dom";
function Bethistory() {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [betHistoryData, setBetHistoryData] = useState([]);
  const navigate = useNavigate();
  const fetchDataFromAPI = async () => {
    try {
      const token = localStorage.getItem("token");
      const apiUrl =  `${process.env.REACT_APP_API_URL}bet-history`;

      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch data from the API");
      }
      const data = await response.json();
      //  alert(data.data);
      console.log(data.data);
      setBetHistoryData(data.data);
      console.log(data.data)
      setIsLoading(false);
    } catch (error) {
      setError(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchDataFromAPI();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }
  const tabs = document.querySelectorAll(".tabthird");

  function tabify(tab) {
    const tabList = tab.querySelector(".tab__listthird");

    if (tabList) {
      const tabItems = [...tabList.children];
      const tabContent = tab.querySelector(".tab__contentthird");
      const tabContentItems = [...tabContent.children];
      let tabIndex = 0;

      tabIndex = tabItems.findIndex((item, index) => {
        return [...item.classList].indexOf("is--activethird") > -1;
      });

      tabIndex > -1 ? (tabIndex = tabIndex) : (tabIndex = 0);

      function setTab(index) {
        tabItems.forEach((x, index) => x.classList.remove("is--activethird"));
        tabContentItems.forEach((x, index) =>
          x.classList.remove("is--activethird")
        );

        tabItems[index].classList.add("is--activethird");
        tabContentItems[index].classList.add("is--activethird");
      }

      tabItems.forEach((x, index) =>
        x.addEventListener("click", () => setTab(index))
      );
      setTab(tabIndex);
      tab
        .querySelectorAll(".tabthird")
        .forEach((tabContent) => tabify(tabContent));
    }
  }

  tabs.forEach(tabify);

  
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1; 
    const year = date.getFullYear();
    return `${day < 10 ? '0' : ''}${day}-${month < 10 ? '0' : ''}${month}-${year}`;
  };
  
  const handleLinkClick = () => {
    navigate('/Home');
  };
  
  return (
    <>
      {/* <div
        class="card-header tab_first d-flex"
        style={{ height: "55px" }}
      ></div> */}

      <section className="bethistory">
        <main id="main" class="main">
            <div class="accountstatement d-flex justify-content-between">
              <h5 className="text-white">Bet History</h5>
              <button className="text-light btn bg-success text-info"  onClick={handleLinkClick}>Back</button>
            </div>

            <div className="account_statement mt-2">
              <div className="container-fluid">
                <div className="row align-items-end mb-2">
                  <div className="col-md-4">
                     <div className="d-flex justify-content-between align-items-end">
                  <Form.Select className="selectbet" aria-label="Default select example">
                <option>Sport Type</option>
                <option value="1">Cricket</option>
                <option value="2">Tennis</option>
                <option value="3">FootBall</option>
             
              </Form.Select>
                  <Form.Select className="selectbet mx-1"  aria-label="Default select example">
                <option>Bet Status</option>
                <option value="1">Matched</option>
                <option value="2">Unmatched</option>
             
              </Form.Select>
              </div>
                  </div>
                  <div class="col-md-8">
                    <form id="search" role="form" className="mb-0"> 
                      <div class="row d-flex align-items-end">
                        <div class="col-md-4 col-6">
                          <label>From Date</label>
                          <input
                            type="date"
                            class="form-custum"
                            value="2015-06-17"
                            id="dateFrom"
                          />
                        </div>

                        <div class="col-md-4 col-6">
                          <label>To Date</label>
                          <input
                            type="date"
                            class="form-custum"
                            value="2015-06-19"
                            id="dateTo"
                          />
                        </div>

                        <div class="col-md-4 col-12 d-flex align-items-flex-end gap-2 mt-3">
                          <button
                            class="btn_custom btn bg-color"
                            type="button"
                            id="getJsonSrc"
                          >
                            {/* <i class="fa-solid fa-magnifying-glass pr-5"></i> */}
                            Go
                          </button>
                          <button
                            class="btn_custom btn bg-color"
                            type="button"
                            id="getJsonSrc"
                          >
                            {/* <i class="fa-solid fa-magnifying-glass pr-5"></i> */}
                            Reset
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>

       
                  <div className="col-md-12 table_details mt-3">
                    
                                <div className="table-responsive">
                                  <table className="table profit-loss-table accountstmt">
                                    <thead>
                                      <tr>
                                        <th className="col-xs-1" data-field="date">
                                        Event Type	
                                        </th>
                                        <th
                                          className="col-xs-1"
                                          data-field="operator"
                                        >
                                          Event Name
                                        </th>
                                        <th className="col-xs-2" data-field="type">
                                          Mobile Number
                                        </th>
                                        <th
                                          className="col-xs-2"
                                          data-field="subType"
                                        >
                                          Runner Name
                                        </th>
                                        <th
                                          className="col-xs-5"
                                          data-field="message"
                                        >
                                          Bet Type
                                        </th>
                                        <th
                                          className="col-xs-5"
                                          data-field="message"
                                        >
                                          User Rate	
                                        </th>
                                        <th
                                          className="col-xs-5"
                                          data-field="message"
                                        >
                                         Amount
                                        </th>
                                        <th
                                          className="col-xs-5"
                                          data-field="message"
                                        >
                                         Place Date	
                                        </th>
                                        <th className="col-xs-5" data-field="message">
                                        Match Date

                          </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {betHistoryData && betHistoryData.map((item) => (
                                        <tr key={item.id}>
                                          <td>{item.event_id}</td>
                                          <td>
                                            {item.event_name
                                              ? item.event_name.name
                                              : "NULL"}
                                          </td>
                                          <td>
                                            {item.user
                                              ? item.user.mobile
                                              : "NULL"}
                                          </td>
                                          <td>
          {item.bet_on === "lay" ? (
            `${item.team} / ${item.odd} /  no`
          ) : item.bet_on === "back" ? (
            `${item.team} / ${item.odd} /  yes`
          ) : (
            `${item.team} / ${item.odd} / ${item.bet_on}`
          )}
        </td>
                                          <td>{item.bet_type}</td>
                                          <td>{item.total}</td>
                                          <td>{item.stake}</td>
                                          <td>{formatDate(item.created_at)}</td>
                                          <td>{formatDate(item.event_name && item.event_name.created_at)}</td> 
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                              
                            </div>
                          </div>
                          
                       
            </div>
        </main>
      </section>
    </>
  );
}

export default Bethistory;
