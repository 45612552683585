import React from 'react'
import andarbahar from '../assets/images/livegame/andarbahar.png'
import dragontiger from '../assets/images/livegame/dragontiger.png'
import livecasino from '../assets/images/livegame/livecasino.png'
import luckyseven from '../assets/images/livegame/luckyseven.png'
import roulette from '../assets/images/livegame/roulette.png'
import teenpati from '../assets/images/livegame/teenpati.png'
export default function Livecasino() {
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-3">
          <div className="gameimage">
            <img src={andarbahar} />
          </div>

        </div>
       
        <div className="col-md-3">
        <div className="gameimage">

          <img src={livecasino} />
        </div>
        </div>
        <div className="col-md-3">
          <div className="gameimage">

            <img src={dragontiger} />
          </div>
        </div>
        <div className="col-md-3">
          <div className="gameimage">

            <img src={luckyseven} />
          </div>
        </div>
      
        <div className="col-md-3">
          <div className="gameimage">

            <img src={roulette} />
          </div>
        </div>
        
        <div className="col-md-3">
          <div className="gameimage">

            <img src={teenpati} />
          </div>
        </div>

      </div>
    </div>
  )
}
