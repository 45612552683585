import React, { useState, useEffect } from "react";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./Login.scss";
import { IoCall } from "react-icons/io5";
import { MdPassword } from "react-icons/md";
import Swal from "sweetalert2";
import Logo from "../assets/images/logosec.png";
import callnow from "../assets/images/callnow2.png";
import phoneIcon from "../assets/images/phoneIcon.png";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { v4 as uuidv4 } from "uuid";
const Login = () => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [showOtpField, setShowOtpField] = useState(false);
  const [phoneError, setPhoneError] = useState("");
  const [otpError, setOtpError] = useState("");
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const userid = localStorage.getItem("userid");
  const [browserId, setBrowserId] = useState("");
  useEffect(() => {
    let browserIdd = Cookies.get("browserId");

    if (!browserIdd) {
      browserIdd = uuidv4();
      Cookies.set("browserId", browserIdd, { expires: 20 }); // Store the ID in a cookie that expires in 365 days
    }
    setBrowserId(browserIdd);
    // alert(browserIdd);

    console.log("Browser ID:", browserId);
  }, []);
  useEffect(() => {
    if (token && userid) {
      navigate("/Home");
    } else {
      navigate("/");
    }
  }, []);

  const handlePhoneNumberChange = (e) => {
    const value = e.target.value;
    const formattedValue = value.replace(/[^0-9]/g, "").slice(0, 10);
    setPhoneNumber(formattedValue);
  };

  const handleOtpChange = (e) => {
    const value = e.target.value;
    setOtp(value);

    if (value.length === 0) {
      setOtpError("OTP cannot be empty.");
    } else {
      setOtpError("");
    }
  };

  const handleLogin = async () => {
    if (phoneNumber.length !== 10) {
      Swal.fire("Error", "Phone number must be correct.", "error");
      return;
    }

    try {
      const loginResponse = await axios.post(
        "https://leobook.in/Admin/api/v1/login",
        {
          phone_number: phoneNumber,
        }
      );

      if (loginResponse.status === 200) {
        Swal.fire({
          title: "Success",
          html: '<b style="color:green;">OTP</b> sent successfully!',
          icon: "success",
          timer: 2000,
          timerProgressBar: true,
          didOpen: () => {
            Swal.showLoading();
          },
          willClose: () => {
            Swal.hideLoading();
          },
        });
        setShowOtpField(true);
      }
    } catch (error) {
      Swal.fire({
        title: "Error",
        html: 'Failed to send <b style="color:red;">OTP</b>',
        icon: "error",
        timer: 2000,
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading();
        },
        willClose: () => {
          Swal.hideLoading();
        },
      });
    }
  };

  const handleOtpVerify = async () => {
    if (otpError || !otp) {
      Swal.fire("Error", otpError || "Please enter the OTP.", "error");
      return;
    }

    try {
      const otpVerifyResponse = await axios.post(
        "https://leobook.in/Admin/api/v1/otpverify",
        {
          phone_number: phoneNumber,
          otp: otp,
          device_id: browserId,
        }
      );

      if (otpVerifyResponse.status === 200) {
        Swal.fire({
          title: "Login Successful",
          html: 'You are now <b style="color:green;">logged in!</b>',
          icon: "success",
          timer: 2000,
          timerProgressBar: true,
          didOpen: () => {
            Swal.showLoading();
          },
          willClose: () => {
            Swal.hideLoading();
            navigate("/Home");
          },
        }).then(() => {
          console.warn(otpVerifyResponse.data.data);
          localStorage.setItem("token", otpVerifyResponse.data.data.token);
          localStorage.setItem("userid", otpVerifyResponse.data.data.user.id);
          localStorage.setItem("phone_number", phoneNumber);
          // setTimeout(() => {

          //   navigate('/Home');
          // }, 1000);
        });
      }
    } catch (error) {
      Swal.fire({
        title: "Error",
        html: '<b style="color:red;">OTP</b> verification failed',
        icon: "error",
        timer: 2000,
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading();
        },
        willClose: () => {
          Swal.hideLoading();
        },
      });
    }
  };

  const handleLinkClick = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You will be redirected to https://utopian.io",
      icon: "warning",
      confirmButtonText: "Yes, visit link!",
      showCancelButton: true,
    }).then((result) => {
      if (result.value) {
        window.location = "https://utopian.io";
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your stay here :)", "error");
      }
    });
  };
  const [PhoneSupport, setPhoneSupport] = useState([]);
  const [PhoneSupport1, setPhoneSupport1] = useState([]);
  useEffect(() => {
    fetchGenralSetting();
  }, []);
  const fetchGenralSetting = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const headers = {
      "Content-Type": "application/json",
    };

    fetch(`${process.env.REACT_APP_API_URL}normal-setting`, {
      method: "GET",
      headers: headers,
    })
      .then((response) => response.json())
      .then((data) => {
        setPhoneSupport(data.data.mobile);
        setPhoneSupport1("tel:" + data.data.mobile);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };
  return (
    <div className="authincation">
      <div className="loginpage">
        <h3 className="text-center">Welcome To</h3>
        <div className="logo">
          <img src={Logo} alt="logo" />
        </div>
        {/* <div>
      <div>
    
        <button onClick={handleSuccessClick}>Success</button>
        <button onClick={handleErrorClick}>Error</button>
        <button onClick={handleWarningClick}>Warning</button>
        <button onClick={handleInfoClick}>Info</button>
        <button onClick={handleQuestionClick}>Question</button>
      </div>
      <br />
      <div>

        <button onClick={handleIconClick}>Custom Icon</button>
      </div>
      <br />
     
      <div>

        <button onClick={handleLinkClick}>Redirect to Utopian</button>
      </div>
    </div> */}
        <h2>LOGIN / REGISTER</h2>
        <div className="loginnmumber">
          <div className="d-flex">
            <div className="icon_number">
              <IoCall />
            </div>
            <Form.Control
              type="text"
              placeholder="Enter Mobile Number"
              className="inputlogin"
              value={phoneNumber}
              name="phoneNumber"
              onChange={handlePhoneNumberChange}
              // isInvalid={!phoneError}
            />
          </div>
          <Form.Control.Feedback type="invalid">
            {phoneError}
          </Form.Control.Feedback>
        </div>
        {showOtpField && (
          <div className="loginnmumber mt-2">
            <div className="d-flex">
              <div className="icon_number">
                <MdPassword />
              </div>
              <Form.Control
                type="text"
                className="inputlogin"
                placeholder="Enter OTP"
                value={otp}
                onChange={handleOtpChange}
                // isInvalid={!!otpError}
              />
            </div>
            <Form.Control.Feedback type="invalid">
              {otpError}
            </Form.Control.Feedback>
          </div>
        )}
        {!showOtpField ? (
          <button onClick={handleLogin} className="btn btn_custum">
            Send OTP
          </button>
        ) : (
          <button onClick={handleOtpVerify} className="btn btn_custum">
            Continue
          </button>
        )}{" "}
        {/* <Link to="/index" className="btn btn_custum">Continue</Link> */}
        <div className="adultlogindetaisl">
          <span>
            By logging in, you accept you are 18+ and agree to our&nbsp;
            <a
              id="tnc_cta_register_screen"
              clasName="link"
              href="void:javascript(0);"
            >
              T&amp;C
            </a>
            &nbsp;&amp;&nbsp;
            <a
              id="privacy_cta_register_screen"
              clasName="link"
              href="void:javascript(0);"
            >
              Privacy Policy
            </a>
          </span>
        </div>
        <div
          className="contactno"
          style={{
            fontSize: "17px",
            borderRadius: "24px",
            backgroundColor: "#212529",
          }}
        >
          <Link to={PhoneSupport1} style={{ color: "#ffffff" }}>
            <img src={phoneIcon} style={{ width: "35px" }} />
            {PhoneSupport}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Login;
