import React from 'react';
import Footer from '../Page/Footer'

const Faq = () => {
  return (
    <div>
          <div className="terms-container">
          <h1 className="terms-heading">Frequently Asked Questions (FAQ)</h1>
      
      <section className="terms-section">
        <h2>1. What is Leobook?</h2>
        <p className="terms-paragraph">

          Leobook is a premier online cricket betting platform designed for cricket enthusiasts. We offer a range of betting options, real-time updates, and a user-friendly interface to enhance your cricket betting experience.
        </p>
      </section>
      
      <section className="terms-section">
        <h2>2. How do I create an account on Leobook?</h2>
        <p>
          To create an account, simply click on the "Sign Up" button on our homepage. Follow the prompts to enter your personal information, choose a secure password, and agree to our terms and conditions. Once registered, you can log in and start betting.
        </p>
      </section>
      
      <section className="terms-section">
        <h2>3. What payment methods are accepted?</h2>
        <p>
          Leobook supports various payment methods including credit/debit cards, bank transfers, and popular e-wallets. For more details on accepted methods, please visit our payment options page or contact our support team.
        </p>
      </section>
      
      <section className="terms-section">
        <h2>4. How can I place a bet?</h2>
        <p>
          After logging in, navigate to the cricket matches or events you wish to bet on. Select the match, choose your bet type, enter the amount, and confirm your bet. You can view your active bets and betting history in your account dashboard.
        </p>
      </section>
      
      <section className="terms-section">
        <h2>5. How do I withdraw my winnings?</h2>
        <p>
          To withdraw your winnings, go to the "Withdraw" section in your account settings. Choose your preferred withdrawal method and enter the amount you wish to withdraw. Please note that withdrawals are subject to verification and processing times.
        </p>
      </section>
      
      <section className="terms-section">
        <h2>6. What should I do if I forget my password?</h2>
        <p>
          If you forget your password, click on the "Forgot Password" link on the login page. Follow the instructions to reset your password using your registered email address. If you encounter any issues, contact our support team for assistance.
        </p>
      </section>
      
      <section className="terms-section">
        <h2>7. Is my personal information secure?</h2>
        <p>
          Yes, we prioritize your privacy and use industry-standard security measures to protect your personal information. Our platform employs encryption and secure protocols to ensure your data is safe from unauthorized access.
        </p>
      </section>
      
      <section className="terms-section">
        <h2>8. How can I contact customer support?</h2>
        <p>
          You can reach our customer support team via email at <a href="#">support@leobook.com</a>. We are available 24/7 to assist you with any questions or issues you may have. Alternatively, you can also use our live chat feature for immediate assistance.
        </p>
      </section>
      
      <section className="terms-section">
        <h2>9. Can I bet on matches that are already in progress?</h2>
        <p>
          Yes, Leobook offers live betting options that allow you to place bets on ongoing matches. Check the "Live Betting" section for available events and live odds.
        </p>
      </section>
      
      <section className="terms-section">
        <h2>10. What measures do you have in place for responsible betting?</h2>
        <p>
          Leobook is committed to promoting responsible betting. We provide tools to help you manage your betting activities, including deposit limits, self-exclusion options, and access to support resources. For more information, visit our responsible betting page.
        </p>
      </section>
    </div>
    <Footer/>
    </div>  
  );
};

export default Faq;
