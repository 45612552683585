import React from 'react';

const Table = () => {
   return(
    <>
     <table>
        <tbody>
            <tr>
                <td>
                </td>
                <td></td>
            </tr>
        </tbody>
     </table>
    </>
   )
};

export default Table;
